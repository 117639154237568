const SkeletonLoader = () => {
    return (
        <div style={styles.skeletonContainer}>
            <div style={styles.skeletonImage} />
            <div style={styles.skeletonDots} />
        </div>
    );
};

const styles = {
    skeletonContainer: {
        width: "100%",
        maxWidth: "600px",
        height: "420px",
        position: "relative",
        borderRadius: "8px",
        backgroundColor: "#f0f0f0", // Light grey background
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    skeletonImage: {
        width: "100%",
        height: "100%",
        backgroundColor: "#e0e0e0", // Slightly darker grey for image
        borderRadius: "8px",
    },
    skeletonDots: {
        position: "absolute",
        bottom: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
};

export default SkeletonLoader;
