import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/userContext";
import bg_circles from "../../images/bg-circle.svg";
import credit_card from "../../images/icons/ic_baseline-credit-card.svg";
import message from "../../images/icons/message.svg";
import phone from "../../images/icons/phone.png";
import instagram from "../../images/lets-icons_insta.png";
import facebook from "../../images/mingcute_facebook-fill.png";
import AnimatedPage from "../FramerMotion/AnimatedPage";
import Navbar from "../Navbar/Navbar";
import OfferBox from "../Offer/OfferBox";
import SocialLink from "./SocialLink";

import JsBarcode from "jsbarcode";
import logo from "../../images/logo.png";
import OfferBoxSkeleton from "../Offer/OfferBoxSkeleton";

function Home({ appSettings, emitEvent }) {
    const {
        ClientId: clientID,
        AppBaseURL1,
        AppBaseURL: baseURL,
        AdminCRMDataURL,
        AdminFileSrvURL,
        AppDisplayName,
        LogoURL,
    } = appSettings;

    const { authUser } = useAuth();

    const homeSection = useRef();

    const [bigDashClass, setBigDashClass] = useState("height-300 opacity-100");

    const [smallDashClass, setSmallDashClass] = useState("opacity-0 height-0");

    const [customerData, setCustomerData] = useState({});

    const [topPromotions, setTopPromotions] = useState([]);

    const [promotions, setPromotions] = useState([]);

    const [contactInfo, setContactInfo] = useState({});

    const [socialLinks, setSocialLinks] = useState([]);

    useEffect(() => {
        if (authUser) {
            homeSection.current.addEventListener("scroll", (e) => {
                if (e.target.scrollTop > 2) {
                    setBigDashClass("height-0 opacity-0");
                    setSmallDashClass("opacity-1 height-50");
                } else {
                    setBigDashClass("height-300 opacity-100");
                    setSmallDashClass("opacity-0 height-0");
                }
            });
            getCustomerData();
            getTopPromotions();
            getPromotions();
            JsBarcode(
                ".js-bar-code",
                authUser ? authUser.CUST_CODE : "000000000",
                {
                    displayValue: false,
                }
            );
            getContactInfo();
            getSocialLinks();
        }
    }, [authUser]);
    const getCustomerData = async () => {
        const payLoad = {
            DIV_DES: "",
            DIV_ID: "1",
            FUNCTION: "GetLoyPoints",
            SEND_KEY: "123456",
            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                TYPE_NAME: "CUSTOMER",
                MOBILE_NUM: authUser ? authUser.MOBILE : "",
                CLIENT_ID: clientID,
            },
        };

        try {
            const request = await fetch(`${baseURL}/GetLoyPoints`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payLoad),
            });

            // Check if the response is OK (status in the range 200-299)
            if (!request.ok) {
                throw new Error(`HTTP error! Status: ${request.status}`);
            }

            const response = await request.json();
            setCustomerData(response ? response : {});
        } catch (error) {
            console.error("Error fetching customer data: ", error);
            // You might want to set an error state here or handle it in another way
            setCustomerData({});
        }
    };

    // const getTopPromotions = async () => {
    //     const payLoad = {
    //         DIV_DES: "",
    //         DIV_ID: "1",
    //         FUNCTION: "LoyGetPromotions",
    //         SEND_KEY: "123456",
    //         DATA: {
    //             AUTH_KEY: "TXlDb206TG95QVBJMTIz",
    //             LOC_ID: "001",
    //             LOY_EXC: 0,
    //             CLIENT_ID: clientID,
    //         },
    //     };
    //     const request = await fetch(`${baseURL}/LoyGetPromotions`, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify(payLoad),
    //     });

    //     const response = await request.json();
    //     if (response.STATUS) {
    //         setTopPromotions(response.PROMOTIONS);
    //         console.log(response.PROMOTIONS, "top promotions");
    //     }
    // };

    const [loadingPromotion, setLoadingPromotion] = useState(false); // Step 1: Add loading state

    const getTopPromotions = async () => {
        const payLoad = {
            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                CLIENT_ID: appSettings.ClientId, // updated CLIENT_ID
                LOC_ID: "ALL", // updated LOC_ID
                LOY_EXC: 0,
            },
        };

        setLoadingPromotion(true); // Step 2: Set loading to true when request starts
        try {
            const request = await fetch(AdminCRMDataURL + "LoyGetPromotions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payLoad),
            });

            const response = await request.json();
            if (response.STATUS) {
                setTopPromotions(response.PROMOTIONS);
            } else {
                console.error("Failed to get promotions:", response);
            }
        } catch (error) {
            console.error("Error fetching promotions:", error);
        } finally {
            setLoadingPromotion(false); // Step 3: Set loading to false after request is complete
        }
    };

    const getPromotions = async () => {
        const payLoad = {
            DIV_DES: "",
            DIV_ID: "1",
            FUNCTION: "LoyGetPromotions",
            SEND_KEY: "123456",
            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                LOC_ID: "001",
                LOY_EXC: 1,
                CLIENT_ID: clientID,
            },
        };
    
        try {
            const request = await fetch(`${baseURL}/LoyGetPromotions`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payLoad),
            });
    
            // Check if the response is OK (status in the range 200-299)
            if (!request.ok) {
                throw new Error(`HTTP error! Status: ${request.status}`);
            }
    
            // Parse the JSON body only once
            const response = await request.json();
    
            // Assuming STATUS is a boolean or truthy value if successful
            if (response?.STATUS) {
                setPromotions(response.PROMOTIONS);
            } else {
                console.error(
                    "Error fetching promotions:",
                    response?.MESSAGE || "No promotions available"
                );
                setPromotions([]); // Clear promotions if fetching failed
            }
        } catch (error) {
            console.error("Error fetching promotions:", error);
            setPromotions([]); // Set promotions to an empty array or handle it differently
        }
    };
    

    const getContactInfo = async () => {
        const payLoad = {
            DATA: {
                client_id: clientID,
                type: "CONTACT_US",
                main_loc: "Y",
            },
        };

        try {
            const request = await fetch(
                `${AppBaseURL1}Loy_GetLocationDetails`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payLoad),
                }
            );

            // Check if the response is OK (status in the range 200-299)
            if (!request.ok) {
                throw new Error(`HTTP error! Status: ${request.status}`);
            }

            const response = await request.json();

            // Check if the response status is "TRUE"
            if (response?.STATUS === "TRUE") {
                setContactInfo(response.DATA?.[0]); // Updated to use optional chaining here
            } else {
                console.error(
                    "Error fetching contact info:",
                    response?.MESSAGE || "No contact info available"
                );
                // Handle the case where STATUS is not "TRUE"
            }
        } catch (error) {
            console.error("Error fetching contact info:", error);
            // Handle the error accordingly, e.g., setContactInfo(null) or show an error message
            setContactInfo({}); // Set contact info to null or handle it differently
        }
    };

    const getSocialLinks = async () => {
        const payLoad = {
            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                CLIENT_ID: clientID,
            },
        };

        try {
            const request = await fetch(`${baseURL}/DownloadSocialLinks`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payLoad),
            });

            // Check if the response is OK (status in the range 200-299)
            if (!request.ok) {
                throw new Error(`HTTP error! Status: ${request.status}`);
            }

            const response = await request.json();
            console.log(response?.SOCIALS, "social");

            // Check if the response status is truthy
            if (response?.STATUS) {
                setSocialLinks(response?.SOCIALS);
            } else {
                console.error(
                    "Error fetching social links:",
                    response?.MESSAGE || "No social links available"
                );
                // Handle the case where STATUS is not truthy
            }
        } catch (error) {
            console.error("Error fetching social links:", error);
            // Handle the error accordingly, e.g., setSocialLinks([]) or show an error message
            setSocialLinks([]); // Set social links to an empty array or handle it differently
        }
    };

    const filteredTopPromotions = topPromotions.filter(
        (promotion) => promotion?.valid?.toLowerCase() === "y"
    );

    // console.log(filteredTopPromotions, "fp");

    return (
        <AnimatedPage>
            <div
                className="modal fade max-width-700 start-50"
                style={{
                    transform: "translateX(-50%)",
                }}
                id="barCodeModal"
                tabIndex="-1"
                aria-labelledby="barCodeModalLabel"
                aria-hidden="true"
            >
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backdropFilter: "blur(10px)",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        zIndex: 1000, // Adjust as needed to ensure it's above other content
                    }}
                >
                    <div className={`modal-dialog modal-dialog-centered width-300 mx-auto max-width-700 ${bigDashClass}`} >
                        <div className="modal-content">
                            <div className="modal-header border-white">
                                <button
                                    type="button"
                                    className="btn btn-close position-relative z-1 end-0 pe-0 text-white"
                                    data-bs-dismiss="modal"
                                ></button>
                            </div>
                            <div className="modal-body text-center height-350 p-0">
                                <div
                                    className="overflow-hidden rounded-2 width-400 height-300"
                                    style={{
                                        transform: `translateY(0px) translateX(-3.2rem) rotate(90deg)`,
                                    }}
                                >
                                    <div>
                                        <p className="mb-0 bg-wafaa-purple text-white font-size-10 d-flex justify-content-end align-items-center py-2 pe-2">
                                            www.
                                            {AppDisplayName
                                                ? AppDisplayName
                                                : "truevalue"}
                                            .com |
                                            <img
                                                src={facebook}
                                                alt="Facebook"
                                                className="height-20 ms-2 me-1"
                                            />
                                            <img
                                                src={instagram}
                                                alt="Instagram"
                                                className="height-20 me-2"
                                            />
                                            {AppDisplayName
                                                ? AppDisplayName
                                                : "TrueValueUAE"}
                                        </p>
                                    </div>
                                    <div className="text-black bg-black py-2">
                                        T
                                    </div>
                                    <img
                                        className="js-bar-code"
                                        src="#"
                                        alt="Barcodes"
                                    />
                                    <div
                                        className="w-100 bg-wafaa-purple py-3"
                                        style={{
                                            backgroundImage: `url(${bg_circles})`,
                                        }}
                                    >
                                        <div className="row m-0 p-0 justify-content-between">
                                            <div className="col-4 text-center">
                                                <img
                                                    alt="Logo"
                                                    className="height-60"
                                                    src={LogoURL}
                                                    onError={(e) => {
                                                        e.target.src = logo;
                                                    }}
                                                />
                                                <div className="d-flex justify-content-between font-size-7 align-items-center">
                                                    <span className="text-white">
                                                        UAE
                                                    </span>
                                                    <span className="rounded-circle height-5 width-5 overflow-hidden text-white bg-white">
                                                        X
                                                    </span>
                                                    <span className="text-white">
                                                        QATAR
                                                    </span>
                                                    <span className="rounded-circle height-5 width-5 overflow-hidden text-white bg-white">
                                                        X
                                                    </span>
                                                    <span className="text-white">
                                                        SAUDI
                                                    </span>
                                                    <span className="rounded-circle height-5 width-5 overflow-hidden text-white bg-white">
                                                        X
                                                    </span>
                                                    <span className="text-white">
                                                        OMAN
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-7 font-size-7 text-start text-white">
                                                {AppDisplayName
                                                    ? AppDisplayName + " "
                                                    : "True Value "}{" "}
                                                Rewards Cards are the exclusive
                                                property of MyCOM Group. In the
                                                event of discovering a lost
                                                card, we kindly request its
                                                return to the nearest MyCOM Tech
                                                outlet. For assistance or
                                                inquiries, please reach out to
                                                us via
                                                <p className="font-size-7 mb-0">
                                                    Email -{" "}
                                                    {AppDisplayName
                                                        ? AppDisplayName
                                                        : "truevalue"}
                                                    @mycom.com
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="overflow-hidden bg-wafaa-purple-2 text-center pt-3 w-100 text-white position-sticky z-2"
                style={{
                    backgroundImage: `url(${bg_circles})`,
                }}
            >
                <div className={"w-100 transition-1-5-bounce "}>
                    <img
                        src={LogoURL}
                        onError={(e) => {
                            e.target.src = logo;
                        }}
                        alt="Logo"
                        className="height-40"
                    />
                    <div className="w-90 mx-auto">
                        <div
                            className="bg-white rounded-4 p-2 my-4"
                            data-bs-toggle="modal"
                            data-bs-target="#barCodeModal"
                        >
                            <p className="text-black text-start">
                                Welcome, <b>{customerData?.CUST_NAME}</b>
                            </p>
                            <img
                                className="js-bar-code w-50 height-50 object-fit-cover"
                                src="#"
                                alt="Barcodes"
                            />
                            <p className="text-wafaa-dark-blue font-size-12">
                                {authUser ? authUser.CUST_CODE : "00000000000"}
                            </p>
                            <p className="text-wafaa-grey font-size-9">
                                Click to expand
                            </p>
                        </div>

                        <div className="w-100 d-flex justify-content-between mt-3 font-size-13 text-start">
                            <div className="p-0 m-0">
                                <p className="font-weight-400 m-0">
                                    Points balance
                                </p>
                                <p className="font-weight-700">
                                    {customerData?.CURRENTPOINTS
                                        ? customerData.CURRENTPOINTS
                                        : "00"}
                                </p>
                            </div>
                            <div className="p-0 m-0">
                                <p className="font-weight-400 m-0">
                                    Points value
                                </p>
                                <p className="font-weight-700">
                                    {customerData?.POINTS_VALUE
                                        ? customerData.POINTS_VALUE
                                        : "0"}{" "}
                                    AED
                                </p>
                            </div>
                            <div className="p-0 m-0">
                                <p className="font-weight-400 m-0">Your type</p>
                                <p className="font-weight-700">
                                    {authUser ? authUser.CARD_TYPE : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "w-100 pb-2 transition-0-5-bounce position-relative z-1 " +
                        smallDashClass
                    }
                >
                    <div className="w-90 mx-auto d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="height-40 width-40 font-size-12 border border-2 border-wafaa-purple-light rounded-circle border d-flex justify-content-center align-items-center">
                                <img
                                    className="h-100 w-100"
                                    alt="Profile"
                                    src={authUser ? authUser.PROFILE_IMAGE : ""}
                                />
                            </div>
                            <div className="d-flex flex-column text-start ms-3">
                                <span className="font-size-14">
                                    {authUser ? authUser.CUST_NAME : ""}
                                </span>
                                <span className="font-size-14 font-weight-700">
                                    {customerData.CURRENTPOINTS
                                        ? customerData.CURRENTPOINTS
                                        : "0"}{" "}
                                    pts(
                                    {customerData.POINTS_VALUE
                                        ? customerData.POINTS_VALUE
                                        : "0"}{" "}
                                    AED)
                                </span>
                            </div>
                        </div>
                        <div
                            data-bs-toggle="modal"
                            data-bs-target="#barCodeModal"
                            className="d-block"
                        >
                            <img
                                src={credit_card}
                                alt="Credit card"
                                className="h-auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="w-100 overflow-y-scroll pb-5 transition-0-5-bounce bg-wafaa-white-3 d-flex flex-column justify-content-between"
                ref={homeSection}
            >
                <div>
                    <div className="w-95 mx-auto d-flex justify-content-between text-wafaa-dark-blue-2 mt-3 font-size-16 font-weight-700">
                        <p>All Offers</p>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="scrolling-wrapper">
                                    {!loadingPromotion ? (
                                        filteredTopPromotions.length > 0 ? (
                                            filteredTopPromotions.map(
                                                (promotion) => (
                                                    <OfferBox
                                                        imageURL={
                                                            AdminFileSrvURL
                                                        }
                                                        key={promotion.PROMO_ID}
                                                        promotion={promotion}
                                                    />
                                                )
                                            )
                                        ) : (
                                            <p className="text-center w-100 text-wafaa-black-1">
                                                No promotions
                                            </p>
                                        )
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "1rem",
                                            }}
                                        >
                                            {Array(8)
                                                .fill(0)
                                                .map((_, idx) => (
                                                    <OfferBoxSkeleton
                                                        key={idx}
                                                    />
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="w-95 mx-auto d-flex justify-content-between text-wafaa-dark-blue-2 mt-3 font-size-16 font-weight-700">
                    <p>Only for you</p>
                </div>

                <div className="pb-5">
                    {promotions.length > 0
                        ? promotions.map((promotion) => (
                              <SecondOfferBox
                                  key={promotion.PROMO_ID}
                                  promotion={promotion}
                              />
                          ))
                        : "No promotions"}
                </div> */}

                <div className="pb-5 pt-5 w-90 mx-auto text-wafaa-black-1">
                    <p className="text-wafaa-black-1 font-weight-900 font-size-19">
                        If you require help please contact us.
                    </p>
                    <p className="font-size-12">
                        {contactInfo?.message ? contactInfo.message : ""}
                    </p>
                    <p>
                        <span className="me-3">
                            <img src={phone} alt="Phone" />
                        </span>
                        <a
                            href={`tel:${contactInfo.phone}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            <span>
                                {contactInfo?.phone ? contactInfo.phone : ""}
                            </span>
                        </a>
                    </p>
                    <p>
                        <span className="me-3">
                            <img src={message} alt="Message" />
                        </span>
                        <a
                            style={{
                                textDecoration: "none", // Remove underline
                                color: "inherit", // Set link color to inherit from parent
                            }}
                            href={`mailto:${contactInfo.email}`}
                        >
                            {contactInfo?.email ? contactInfo.email : ""}
                        </a>
                    </p>

                    <div className="col mx-auto d-flex justify-content-center gap-4">
                        {socialLinks?.map((social, idx) => (
                            <SocialLink
                                key={idx}
                                LINK={social.LINK}
                                NAME={social.NAME}
                                VALID={social.VALID}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <Navbar activePage="home" />
        </AnimatedPage>
    );
}

export default Home;
