function SingleStore({ store }) {

    return (
        <div className='bg-wafaa-white-2 w-90 mx-auto min-width-250 overflow-hidden rounded-2 shadow-wafaa mb-3 p-2'>
            <p className="font-weight-bolder mb-0 text-wafaa-black-1">{store.loc_name}</p>
            <p className="mb-0 text-wafaa-grey-1 font-size-13">{store.address}</p>
            <p className="mb-0 text-wafaa-grey-1 font-size-13">
                Contact Details: <br/>
                {store.phone}
            </p>
        </div>
    )
}
export default SingleStore;