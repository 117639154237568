import { useState } from 'react';

export default function useAlert() {

    const [showAlert, setShowAlert] = useState('d-none');
    const [alertStyle, setAlertStyle] = useState({
        background: 'linear-gradient(to right, #32bb71 0%, #2a9d8f 100%)'
    });
    const [alertMessage, setAlertMessage] = useState({
        success: true,
        message: 'Tes',
        mode: 'Test'
    });

    function hideAlert(){
        setShowAlert('d-none');
    }


    return {
        showAlert,
        setShowAlert,
        alertStyle,
        setAlertStyle,
        alertMessage,
        setAlertMessage,
        hideAlert
    };
}