import React, { useRef } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import { useNavigate } from 'react-router-dom';
import Title from './misc/Title';
import Cookies from 'js-cookie';
import Alert from './Alert/Alert';
import useAlert from "../../hooks/Alert";

function ForgotPasswordNewPassword({ baseURL, clientID }) {

    const navigate = useNavigate();

    const {showAlert, setShowAlert, alertStyle, setAlertStyle, alertMessage, setAlertMessage, hideAlert} = useAlert();

    let userData = {};

    try {
        userData = JSON.parse(Cookies.get('wafaa-user-data-reset-email'));
    } catch (error) {
        navigate('/');
    }

    const newPassword = useRef();

    const setNewPassword = async () => {
        if (newPassword.current.checkValidity()) {
            const payload = {
                "DIV_DES": "",
                "DIV_ID": "1",
                "FUNCTION": "ChangePWD",
                "SEND_KEY": "123456",
                "DATA": {
                    "EMAIL_ID": userData,
                    "NEW_PASSWORD": newPassword.current.value,
                    "CLIENT_ID": clientID
                }
            }

            const request = await fetch(`${baseURL}/ChangePWD`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })

            if (request.ok) {
                hideAlert()
                setAlertStyle({
                    background: 'linear-gradient(to right, #32bb71 0%, #2a9d8f 100%)'
                })
                setAlertMessage({
                    success: true,
                    message: "Password reset successfully",
                    mode: 'New Password'
                });
                setShowAlert('d-block');
                setTimeout(() => {
                    Cookies.set('wafaa-user-data-reset-email', null);
                    navigate('/');
                }, 1000);
            } else {
                hideAlert()
                setAlertStyle({
                    background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                })
                setAlertMessage({
                    success: false,
                    message: "Failed to reset password. Please retry",
                    mode: 'New Password'
                });
                setShowAlert('d-block');
            }
        } else {
            hideAlert()
            setAlertStyle({
                background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
            })
            setAlertMessage({
                success: false,
                message: "Invalid password input",
                mode: 'New Password'
            });
            setShowAlert('d-block');
        }
    }
    return (
        <AnimatedPage>
            <Title />
            <Alert showAlert={showAlert} alertStyle={alertStyle} alertMessage={alertMessage} hideAlert={hideAlert} />
            <div className='bg-white vh-100 d-flex flex-column justify-content-between px-4 py-5'>
                <div>
                    <p className='font-size-18 font-weight-bolder'>New Password</p>
                    <input className='bg-wafaa-grey border-0 rounded-2 mt-4 ps-2 py-3 shadow-wafaa-btn-inset form-control' placeholder='Enter new password' ref={newPassword} required type='password' />
                </div>
            </div>
            <div className='position-absolute d-flex flex-column justify-content-end p-4 w-100 bottom-0'>
                <button type='button' className='w-100 btn btn-lg py-2-5 btn-primary bg-wafaa-purple border-wafaa-purple shadow-wafaa-btn font-size-12' onClick={() => { setNewPassword() }}>
                    Continue
                </button>
            </div>
        </AnimatedPage>
    );
}

export default ForgotPasswordNewPassword;
