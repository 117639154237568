import React, { useEffect, useRef, useState } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import sort_icon from '../../images/icons/bx_sort.svg';
import OfferBox from '../Offer/OfferBox';
import SecondOfferBox from '../Offer/SecondOfferBox';
import PageTitle from '../Misc/PageTitle';
import { useAuth } from '../../context/userContext';

import JsBarcode from 'jsbarcode';

function HomeID({ baseURL, clientID, AppDisplayName}) {

    const { authUser } = useAuth();

    const homeSection = useRef();

    const [bigDashClass, setBigDashClass] = useState('height-100 opacity-100');

    const [hiddenDashElementsClass, setHiddenDashElementsClass] = useState('opacity-1 height-10');

    const [dashImageClass, setDashImageClass] = useState('height-60');

    const [customerData, setCustomerData] = useState({});

    const [promotions, setPromotions] = useState([]);

    let lastY;
    function dragSwiper(event) {
        const currentY = event.touches[0].clientY;
        if (currentY > lastY) {
            // moved down
            setBigDashClass('height-200 opacity-100');
            setHiddenDashElementsClass('opacity-1 height-10');
            setDashImageClass('height-60');
        } else if (currentY < lastY) {
            // moved up
            setBigDashClass('height-100 opacity-00');
            setHiddenDashElementsClass('opacity-0 height-0 mb-0')
            setDashImageClass('height-40');
        }
        lastY = currentY;
    }

    useEffect(() => {
        setBigDashClass('height-100 opacity-25');
        setTimeout(() => {
            setBigDashClass('height-200 opacity-100');
        }, 300);
        getCustomerData(baseURL);
        JsBarcode('.js-bar-code', authUser ? authUser.CUSTCODE : '00000');
    }, []);

    const getCustomerData = async (baseURL) => {
        const payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "GetLoyPoints",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "TYPE_NAME": "CUSTOMER",
                "MOBILE_NUM": authUser ? authUser.MOBILE : '',
                "CLIENT_ID": clientID
            }
        };
        const request = await fetch(`${baseURL}/GetLoyPoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        setCustomerData(response);
        getPromotions(baseURL);
    }

    const getPromotions = async (baseURL) => {
        const payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "LoyGetPromotions",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "LOC_ID": "001",
                "CLIENT_ID": clientID
            }
        };
        const request = await fetch(`${baseURL}/LoyGetPromotions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        setPromotions(response.PROMOTIONS);
    }

    return (
        <AnimatedPage>
            <div className='position-sticky z-2'>
                <PageTitle name={` ${AppDisplayName ? AppDisplayName : "TRUE VALUE ID"}`}>
                    <div className={'w-90 mx-auto transition-0-5-bounce ' + bigDashClass}>
                        <div className='bg-white rounded-4 p-2 my-4 w-75 mx-auto text-center'>
                            <p className={'transition-0-5-bounce font-size-12 font-weight-600 text-wafaa-grey ' + hiddenDashElementsClass}>
                                Your Points Balance
                            </p>
                            <p className={'transition-0-5-bounce ' + hiddenDashElementsClass}>
                                <span className='font-size-18 font-weight-700'>{customerData.REDEEMABLEPOINTS ? customerData.REDEEMABLEPOINTS : "00"}</span>
                                <span className='font-size-18 text-wafaa-grey mx-2'>=</span>
                                <span className='font-size-18 font-weight-700'>{customerData.POINTS_VALUE ? customerData.POINTS_VALUE : '0'}</span>
                                <span className='font-size-10 text-wafaa-grey ms-2'>AED</span>
                            </p>
                            <img src='#' alt='Bar code' className={'js-bar-code height-50 height-60 w-70 object-fit-cover ' + dashImageClass} />
                            <p className={'transition-0-5-bounce text-wafaa-grey font-size-9 ' + hiddenDashElementsClass}>Press and hold on your {AppDisplayName ? AppDisplayName : "TRUE VALUE"} ID to copy</p>
                        </div>
                    </div>
                </PageTitle>

                <div className='col-4 mx-auto text-center' onTouchMove={(event) => dragSwiper(event)} draggable={true}>
                    <p className='font-size-12 text-wafaa-dark-blue'>Swipe up to view</p>
                    <span className='d-block mx-auto w-30 text-dark bg-dark rounded-pill font-size-5'>A</span>
                </div>
            </div>


            <div className='w-100 h-95 overflow-y-scroll pb-5 transition-0-5-bounce' ref={homeSection}>

                <div className='w-95 mx-auto d-flex justify-content-between text-wafaa-dark-blue mt-3 font-size-16 font-weight-700'>
                    <p>All Offers</p>

                    <p>More</p>
                </div>

                <div className='w-95 mx-auto'>
                    <div className='w-100 overflow-x-scroll d-flex pt-1 py-3'>
                        {(promotions.length > 0) ? promotions.map(promotion => (
                            <OfferBox promotion={promotion} />
                        )) : "No promotions"}
                    </div>
                </div>

                <div className='w-95 mx-auto d-flex justify-content-between text-wafaa-dark-blue mt-3 font-size-16 font-weight-700'>
                    <p>Only for you</p>

                    <p>
                        Sort
                        <img src={sort_icon} alt='Sort icon' />
                    </p>
                </div>

                <div style={{
                    paddingBottom: '200px'
                }}>
                    {(promotions.length > 0) ? promotions.map(promotion => (
                        <SecondOfferBox promotion={promotion} />
                    )) : "No promotions"}
                </div>

            </div>
        </AnimatedPage>
    );
}

export default HomeID;
