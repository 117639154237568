import { useEffect, useRef, useState } from "react";

import AnimatedPage from "../FramerMotion/AnimatedPage";

import PageTitle from "../Misc/PageTitle";

import Navbar from "../Navbar/Navbar";

import { TableRow, TableRowRedemption } from "./TableRow";

import { useAuth } from "../../context/userContext";

import moment from "moment";

function History({ baseURL, clientID, emitEvent }) {
    const scrollUL = useRef();

    const { authUser } = useAuth();

    const [menu, setMenu] = useState("purchase");

    const [transactionHistory, setTransactionHistory] = useState({});

    const [reedeemedVouchers, setRedeemedVouchers] = useState([]);

    const [customerData, setCustomerData] = useState({});

    const [filterOptions, setFilterOptions] = useState([]);

    const [selectedFilterOptions, setSelectedFilterOptions] = useState("");

    const [highlightedFilterOption, setHighlightedFilterOption] = useState({});

    const [filterBoxDisplay, setFilterBoxDisplay] =
        useState("height-0 opacity-0");

    function switchMenu(menu) {
        setMenu(menu);
    }

    useEffect(() => {
        if (authUser) {
            getCustomerHistoryData(baseURL);

            getCustomerData(baseURL);

            const today = moment();

            const defaultFilterOptions = [
                {
                    name: "Last 3 days",

                    value: today
                        .clone()
                        .subtract(3, "day")
                        .format("YYYY-MM-DD"),
                },

                {
                    name: "Last 7 days",

                    value: today
                        .clone()
                        .subtract(7, "day")
                        .format("YYYY-MM-DD"),
                },

                {
                    name: "Last 30 days",

                    value: today
                        .clone()
                        .subtract(30, "day")
                        .format("YYYY-MM-DD"),
                },

                {
                    name: "Last 60 days",

                    value: today
                        .clone()
                        .subtract(60, "day")
                        .format("YYYY-MM-DD"),
                },

                {
                    name: "Last month",

                    value: today
                        .clone()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                },

                {
                    name: "Last 3 months",

                    value: today
                        .clone()
                        .subtract(3, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                },

                {
                    name: "Last 6 months",

                    value: today
                        .clone()
                        .subtract(6, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                },
            ];

            // Get the current year

            const currentYear = today.year();

            // Dynamically add year options

            for (let year = currentYear; year >= currentYear - 3; year--) {
                defaultFilterOptions.push({
                    name: year.toString(),

                    value: moment(`${year}-01-01`).format("YYYY-MM-DD"),
                });
            }

            setFilterOptions(defaultFilterOptions);

            // Set default selection to the current year

            const currentYearOption = defaultFilterOptions.find(
                (option) => option.name === currentYear.toString()
            );

            setSelectedFilterOptions(currentYearOption.name);

            setHighlightedFilterOption(currentYearOption);
        }
    }, [authUser]);

    useEffect(() => {
        if (filterOptions.length) {
            const listElements = scrollUL.current.querySelectorAll("li");

            let isUserScrolling = false; // Flag to check if user is scrolling

            // Debounced scroll handler
            const handleScroll = debounce((event) => {
                isUserScrolling = true; // Set flag to true when the user starts scrolling

                const elementTop =
                    scrollUL.current?.getBoundingClientRect().top;

                let element,
                    top,
                    lowerMin = -140,
                    lowerMax = -120,
                    upperMin = 25,
                    upperMax = 30;

                for (let i = 0; i < listElements.length; i++) {
                    top =
                        listElements[i]?.getBoundingClientRect().top -
                        elementTop;

                    if (top >= lowerMin && top <= lowerMax) {
                        element = listElements[i];
                        setHighlightedFilterOption({
                            name: element.getAttribute("name"),
                            value: element.getAttribute("value"),
                        });

                        // Trigger vibration for haptic feedback only if user is scrolling
                        if (isUserScrolling && navigator.vibrate) {
                            navigator.vibrate(25); // Vibrate for 25ms
                        }
                    } else if (top >= upperMin && top <= upperMax) {
                        element = listElements[i];
                        setHighlightedFilterOption({
                            name: element.getAttribute("name"),
                            value: element.getAttribute("value"),
                        });

                        // Trigger vibration for haptic feedback only if user is scrolling
                        if (isUserScrolling && navigator.vibrate) {
                            navigator.vibrate(25); // Vibrate for 25ms
                        }
                    }
                }
            }, 100); // Debouncing by 100ms

            // Attach the debounced scroll handler to the scroll event
            scrollUL.current.addEventListener("scroll", handleScroll);

            // Cleanup: remove the event listener when the component unmounts or filterOptions change
            return () => {
                scrollUL?.current?.removeEventListener("scroll", handleScroll);
            };
        }
    }, [filterOptions, setHighlightedFilterOption]);

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;

            clearTimeout(timeout);
            timeout = setTimeout(() => {
                func.apply(context, args);
            }, wait);
        };
    }

    const getCustomerHistoryData = async (baseURL) => {
        const payLoad = {
            DIV_DES: "",

            DIV_ID: "1",

            FUNCTION: "GetLoyPoints",

            SEND_KEY: "123456",

            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",

                TYPE_NAME: "CUSTOMER_HISTORY",

                MOBILE_NUM: authUser ? authUser.MOBILE : "",

                FROM_DATE: moment().subtract(1, "month").format("YYYY-MM-DD"),

                TO_DATE: moment().format("YYYY-MM-DD"),

                CLIENT_ID: clientID,
            },
        };

        const request = await fetch(`${baseURL}/GetLoyPoints`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify(payLoad),
        });

        const response = await request.json();

        if (request.ok && response.STATUS) {
            setRedeemedVouchers(response.VOUCHER_REDEEMED);

            setTransactionHistory(response.ITEM_DETAILS);
        } else {
            setTransactionHistory([]);
        }
    };

    const getCustomerData = async (baseURL) => {
        const payLoad = {
            DIV_DES: "",

            DIV_ID: "1",

            FUNCTION: "GetLoyPoints",

            SEND_KEY: "123456",

            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",

                TYPE_NAME: "CUSTOMER",

                MOBILE_NUM: authUser ? authUser.MOBILE : "",

                CUST_CODE: authUser ? authUser.CUST_CODE : "",

                CLIENT_ID: clientID,
            },
        };

        const request = await fetch(`${baseURL}/GetLoyPoints`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify(payLoad),
        });

        const response = await request.json();

        setCustomerData(response);
    };

    async function filterDate() {
        setSelectedFilterOptions(highlightedFilterOption.name);

        closeFilter();

        const payLoad = {
            DIV_DES: "",

            DIV_ID: "1",

            FUNCTION: "GetLoyPoints",

            SEND_KEY: "123456",

            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",

                TYPE_NAME: "CUSTOMER_HISTORY",

                MOBILE_NUM: authUser ? authUser.MOBILE : "",

                FROM_DATE: highlightedFilterOption.value,

                TO_DATE: moment().format("YYYY-MM-DD"),

                CLIENT_ID: clientID,
            },
        };

        const request = await fetch(`${baseURL}/GetLoyPoints`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify(payLoad),
        });

        const response = await request.json();

        if (request.ok && response.STATUS) {
            const compositeURL = response.BILL_DETAILS;

            const itemDetails = response.ITEM_DETAILS;

            const vouchersIssued = response.VOUCHER_ISSUED;

            const vouchersRedeemed = [...response.VOUCHER_REDEEMED];

            const pointsEarned = response.POINTS_EARNED;

            for (let index = 0; index < compositeURL.length; index++) {
                const billDetail = compositeURL[index];

                // item details

                billDetail.ITEM_DETAILS = [];

                for (
                    let indexItem = 0;
                    indexItem < itemDetails.length;
                    indexItem++
                ) {
                    if (itemDetails[indexItem] === undefined) {
                        continue;
                    }

                    if (itemDetails[indexItem].BILLREF === billDetail.BILLREF) {
                        billDetail.ITEM_DETAILS.push(itemDetails[indexItem]);

                        delete itemDetails[indexItem];
                    }
                }

                // vouchers issued

                billDetail.VOUCHER_ISSUED = [];

                for (
                    let indexVoucherIssue = 0;
                    indexVoucherIssue < vouchersIssued.length;
                    indexVoucherIssue++
                ) {
                    if (vouchersIssued[indexVoucherIssue] === undefined) {
                        continue;
                    }

                    if (
                        vouchersIssued[indexVoucherIssue].BILLREF ===
                        billDetail.BILLREF
                    ) {
                        billDetail.VOUCHER_ISSUED.push(
                            vouchersIssued[indexVoucherIssue]
                        );

                        delete vouchersIssued[indexVoucherIssue];
                    }
                }

                // vouchers redeemed

                billDetail.VOUCHER_REDEEMED = [];

                for (
                    let indexVouchersRedeemed = 0;
                    indexVouchersRedeemed < vouchersRedeemed.length;
                    indexVouchersRedeemed++
                ) {
                    if (vouchersRedeemed[indexVouchersRedeemed] === undefined) {
                        continue;
                    }

                    if (
                        vouchersRedeemed[indexVouchersRedeemed].BILLREF ===
                        billDetail.BILLREF
                    ) {
                        billDetail.VOUCHER_REDEEMED.push(
                            vouchersRedeemed[indexVouchersRedeemed]
                        );

                        delete vouchersRedeemed[indexVouchersRedeemed];
                    }
                }

                // points earned

                billDetail.POINTS_EARNED = [];

                for (
                    let indexPointsEarned = 0;
                    indexPointsEarned < pointsEarned.length;
                    indexPointsEarned++
                ) {
                    if (pointsEarned[indexPointsEarned] === undefined) {
                        continue;
                    }

                    if (
                        pointsEarned[indexPointsEarned].BILLREF ===
                        billDetail.BILLREF
                    ) {
                        billDetail.POINTS_EARNED.push(
                            pointsEarned[indexPointsEarned]
                        );

                        delete pointsEarned[indexPointsEarned];
                    }
                }
            }

            setTransactionHistory(compositeURL);

            setRedeemedVouchers(response.VOUCHER_REDEEMED);
        } else {
            setTransactionHistory([]);
        }
    }

    function openFilter() {
        setFilterBoxDisplay("height-225 opacity-100");
    }

    function closeFilter() {
        setFilterBoxDisplay("height-0 opacity-0");
    }

    return (
        <AnimatedPage>
            <div className="position-sticky z-2">
                <PageTitle name="TRANSACTION HISTORY" />
            </div>

            <div className="w-100 h-85 overflow-y-scroll pb-5 transition-0-5-bounce bg-wafaa-white-3">
                <div className="w-90 mx-auto py-2">
                    <div className="d-flex justify-content-end">
                        <div
                            className="d-flex justify-content-between border-1 col-5 text-center text-dark font-size-11 border p-2 rounded-1 bg-white"
                            onClick={() => openFilter()}
                        >
                            <span className="d-inline-block">
                                {selectedFilterOptions}
                            </span>

                            <span
                                className="d-inline-block"
                                style={{ transform: `rotate(90deg)` }}
                            >
                                &#10095;
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    className="btn-group w-90 d-flex mx-auto my-2"
                    data-toggle="buttons"
                    role="group"
                    aria-label="Basic radio toggle button group"
                >
                    <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        defaultChecked
                    />

                    <label
                        className="btn btn-wafaa-secondary text-wafaa-grey-2 font-size-16"
                        htmlFor="btnradio1"
                        onClick={() => {
                            switchMenu("purchase");
                        }}
                    >
                        My Purchase
                    </label>

                    <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autoComplete="off"
                    />

                    <label
                        className="btn btn-wafaa-secondary text-wafaa-grey-2 font-size-16"
                        htmlFor="btnradio2"
                        onClick={() => {
                            switchMenu("redemption");
                        }}
                    >
                        My redemption
                    </label>
                </div>

                <div className="w-90 mx-auto pb-5">
                    <div className="row px-2">
                        <div className="text-center font-weight-600 text-wafaa-dark-blue-2 font-size-16 py-2 col-4 overflow-hidden transition-0-5-ease ps-1 pe-0">
                            Date
                        </div>

                        <div
                            className={
                                "text-center font-weight-600 text-wafaa-dark-blue-2 font-size-16 py-2 col-4 overflow-hidden transition-0-5-ease px-0 "
                            }
                        >
                            Amount
                        </div>

                        <div
                            className={
                                "text-center font-weight-600 text-wafaa-dark-blue-2 font-size-16 py-2 col-4 overflow-hidden transition-0-5-ease px-0 "
                            }
                        >
                            Points
                        </div>
                    </div>

                    <table className="table bg-transparent table-borderless pb-5 table-fixed">
                        <tbody>
                            {menu === "purchase" ? (
                                transactionHistory.length > 0 &&
                                transactionHistory !== undefined ? (
                                    transactionHistory.map(
                                        (transaction, index) => (
                                            <TableRow
                                                key={transaction.BILLREF}
                                                transaction={transaction}
                                            />
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="100%"
                                            className="bg-transparent px-0 text-center py-3"
                                        >
                                            No transactions
                                        </td>
                                    </tr>
                                )
                            ) : reedeemedVouchers.length > 0 ? (
                                reedeemedVouchers.map((transaction, index) => (
                                    <TableRowRedemption
                                        key={transaction.BILLREF}
                                        transaction={transaction}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="100%"
                                        className="bg-transparent px-0 text-center py-3"
                                    >
                                        No transactions
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="bg-wafaa-purple-2 text-white pt-3 pb-4 w-100 height-120 bottom-25 position-fixed max-width-700 ">
                <div className="row m-0 font-size-12">
                    <div className="col-4">
                        <p className="mb-0 font-weight-400">Points balance</p>

                        <p className="mb-0 font-weight-700">
                            {customerData.REDEEMABLEPOINTS
                                ? customerData.REDEEMABLEPOINTS
                                : "00"}{" "}
                            pts
                        </p>
                    </div>

                    <div className="col-4">
                        <p className="mb-0 font-weight-400">Points value</p>

                        <p className="mb-0 font-weight-700">
                            {customerData.POINTS_VALUE
                                ? customerData.POINTS_VALUE
                                : "0"}{" "}
                            AED
                        </p>
                    </div>

                    <div className="col-4">
                        <p className="mb-0 font-weight-400">Expired Points</p>

                        <p className="mb-0 font-weight-700">
                            {customerData.POINTS_EXPIRED
                                ? customerData.POINTS_EXPIRED
                                : "0"}
                        </p>
                    </div>
                </div>
            </div>

            <div
                className={
                    "max-width-700 bg-wafaa-white-2 w-100 bottom-40 position-fixed pb-5 transition-0-2-ease " +
                    filterBoxDisplay
                }
            >
                <div className="h-100 w-100 position-relative bg-transparent">
                    <div className="position-absolute z-2 top--15 right-10">
                        <button
                            className="btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100"
                            onClick={() => closeFilter()}
                        ></button>
                    </div>
                    <div className="overflow-hidden mb-3 pt-2">
                        <div className="picker w-100 d-inline-grid position-relative">
                            <div className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50"></div>
                            <ul
                                className="bg-wafaa-white-2 max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5 mt-3"
                                ref={scrollUL}
                            >
                                {filterOptions.map((filter, index) => (
                                    <li
                                        className="bg-wafaa-white-2 list-group-item border-0 text-wafaa-black-1 text-center"
                                        name={filter.name}
                                        value={filter.value}
                                        key={index}
                                        onClick={() =>
                                            setHighlightedFilterOption(filter)
                                        }
                                    >
                                        {filter.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-center w-100 px-4 py-3">
                        <button
                            className="btn bg-wafaa-purple w-90 text-white"
                            onClick={() => filterDate()}
                        >
                            DONE
                        </button>
                    </div> */}
                    <div className="text-center">
                        <button
                            className="btn bg-wafaa-purple w-90 text-white"
                            onClick={() => {
                                filterDate();
                            }}
                        >
                            {" "}
                            DONE{" "}
                        </button>
                    </div>
                </div>
            </div>

            <Navbar activePage="history" />
        </AnimatedPage>
    );
}

export default History;
