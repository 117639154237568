const download = async (url, name) => {
    if (!url) {
        throw new Error("Resource URL not provided! You need to provide one");
    }
    const request = await fetch(url);

    const blob = await request.blob();

    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = blobURL;
    a.style = "display: none";
    a.download = name;
    document.body.appendChild(a);
    a?.click();
};

export default download;
