function SocialLink({ LINK, NAME, VALID }) {
    const validLink =
        LINK && !LINK.startsWith("http") ? `https://${LINK}` : LINK;

    return (
        <a
            style={{ display: VALID !== "Y" ? "none" : "block" }}
            target="_blank"
            rel="noreferrer"
            href={
                validLink ||
                `https://${NAME ? NAME.toLowerCase() : "example"}.com`
            }
        >
            <span
                className={`text-secondary font-size-18 fa-brands fa-${
                    NAME ? NAME.toLowerCase() : "example"
                }`}
            ></span>
        </a>
    );
}

export default SocialLink;
