function ToggleButton(){
    const randomNumber = (Math.random()).toString().substring(2, 8);
    return (
        <span className='d-inline-block me-2'>
            <input type="checkbox" className="btn-check" id={"btn-check-outlined-" + randomNumber}/>
            <label className="btn btn-outline-primary mb-3 shadow-wafaa-bg border-0 text-wafaa-dark-blue-2 font-size-12 bg-wafaa-purple-radio px-2 py-2-5" htmlFor={"btn-check-outlined-" + randomNumber}>Shopping</label>
        </span>
    )
}

export default ToggleButton;