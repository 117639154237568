import React, { useEffect, useRef, useState } from "react";
import pdf from "../../images/icons/image 11.png";
import AnimatedPage from "../FramerMotion/AnimatedPage";
import PageTitle from "../Misc/PageTitle";
import Navbar from "../Navbar/Navbar";
import ToggleButton from "./misc/ToggleButton";
import SecondOfferBox from "./SecondOfferBox";
import SecondOfferBoxSkeleton from "./SecondOfferBoxSkeleton";

export default function Offers({ baseURL, clientID, appSettings, emitEvent }) {
    const { AdminCRMDataURL, AdminFileSrvURL, AppDisplayName } = appSettings;

    const [promotions, setPromotions] = useState([]);

    const downloadButton = useRef();

    useEffect(() => {
        getPromotions(baseURL);
    }, []);

    // const getPromotions = async (baseURL) => {
    //     const payLoad = {
    //         "DIV_DES": "",
    //         "DIV_ID": "1",
    //         "FUNCTION": "LoyGetPromotions",
    //         "SEND_KEY": "123456",
    //         "DATA": {
    //             "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
    //             "LOC_ID": "001",
    //             "LOY_EXC" : 0,
    //             "CLIENT_ID": clientID
    //         }
    //     };
    //     const request = await fetch(`${baseURL}/LoyGetPromotions`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(payLoad)
    //     });

    //     const response = await request.json();
    //     setPromotions(response.PROMOTIONS);
    // }

    const [loading, setLoading] = useState(true); // Initialize loading state

    const getPromotions = async () => {
        const payLoad = {
            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                CLIENT_ID: appSettings.ClientId, // updated CLIENT_ID
                LOC_ID: "ALL", // updated LOC_ID
                LOY_EXC: 0,
            },
        };

        try {
            // Start fetching data, set loading to true
            setLoading(true);

            const request = await fetch(AdminCRMDataURL + "LoyGetPromotions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payLoad),
            });

            const response = await request.json();
            if (response.STATUS) {
                setPromotions(response.PROMOTIONS);
            } else {
                console.error("Failed to get promotions:", response);
            }
        } catch (error) {
            console.error("Error fetching promotions:", error);
        } finally {
            // Always stop loading, whether fetch is successful or not
            setLoading(false);
        }
    };

    const downloadBrochure = async () => {
        const payLoad = {
            DATA: { AUTH_KEY: "TXlDb206TG95QVBJMTIz", CLIENT_ID: clientID },
        };
        const request = await fetch(`${baseURL}/DownloadBrouchre`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payLoad),
        });

        const response = await request.json();

        if (response.STATUS) {
            const fileURL = new URL(response.DATA[0].FilePath);
            await download(
                response.DATA[0].FilePath,
                fileURL.pathname.substring(
                    fileURL.pathname.lastIndexOf("/") + 1
                )
            );
        } else {
            alert("Failed to download");
        }
    };

    const download = async (url, name) => {
        if (!url) {
            throw new Error(
                "Resource URL not provided! You need to provide one"
            );
        }
        const request = await fetch(url);

        const blob = await request.blob();

        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";
        a.download = name;
        document.body.appendChild(a);
        a?.click();
    };
    const filteredPromotions = promotions.filter(
        (promotion) => promotion?.valid?.toLowerCase() === "y"
    );

    return (
        <AnimatedPage>
            <div className="position-sticky z-2">
                <PageTitle
                    name={`${
                        AppDisplayName
                            ? AppDisplayName + " Offers"
                            : "TRUE VALUE OFFERS"
                    }`}
                />
            </div>
            <div className="w-100 h-95 overflow-y-scroll pb-5 bg-wafaa-white-3">
                <div className="my-3 mx-auto px-2 d-none">
                    <ToggleButton />
                </div>

                <div className="px-2 text-end d-none">
                    <button
                        onClick={() => {
                            downloadBrochure();
                        }}
                        className="bg-white btn shadow font-size-12"
                    >
                        <img src={pdf} alt="PDF Icon" className="width-30" />
                        <span>Download Brochure</span>
                        <a
                            download="test.txt"
                            href={baseURL}
                            ref={downloadButton}
                            className="d-none"
                        >
                            Download
                        </a>
                    </button>
                </div>

                <div className="text-wafaa-black-1 w-95 mx-auto d-flex justify-content-between text-wafaa-dark-blue-2 mt-3 font-size-16 font-weight-700">
                    <p>All Offers</p>
                </div>

                <div className="pb-5">
                    {
                        loading ? (
                            Array(8)
                                .fill()
                                .map((_, index) => (
                                    <SecondOfferBoxSkeleton key={index} /> // Show skeletons while loading
                                ))
                        ) : filteredPromotions.length > 0 ? (
                            filteredPromotions.map((promotion) => (
                                <SecondOfferBox
                                    imageURL={AdminFileSrvURL}
                                    key={promotion.PROMO_ID}
                                    promotion={promotion}
                                />
                            ))
                        ) : (
                            <p className="text-wafaa-black-1 text-center">
                                No promotions available
                            </p>
                        ) // Show this when there are no promotions
                    }
                </div>
            </div>
            <Navbar activePage="offers" emitEvent={emitEvent} />
        </AnimatedPage>
    );
}
