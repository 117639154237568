import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/userContext';
import { useAppSetting } from '../../context/appSettingContext';

function TransferVoucher() {
    const location = useLocation();

    const navigate = useNavigate();

    const { authUser } = useAuth();

    const { appSettings } = useAppSetting();

    const voucher = location.state;

    const [customerShareExist, setCustomerShareExist] = useState(true);

    const [customerData, setCustomerData] = useState({});

    useEffect(() => {
        if (!voucher) {
            navigate('/vouchers');
            getCustomerData();
        }
    }, [])

    const getCustomerData = async (baseURL) => {
        const payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "GetLoyPoints",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "TYPE_NAME": "CUSTOMER",
                "MOBILE_NUM": authUser ? authUser.MOBILE : '',
                "CLIENT_ID": appSettings.ClientId
            }
        };
        const request = await fetch(`${baseURL}/GetLoyPoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        setCustomerData(response);
    }

    const submitShareForm = async (event) => {
        setCustomerShareExist(true);
        
        event.preventDefault();

        const formData = new FormData(event.target);

        const payLoad = {
            "DATA": {
                "voucherEntryNo": voucher.entry_no,
                "senderMobile": customerData.MOBILE,
                "senderEmail": customerData.Email,
                "receiverMobile": formData.get('recipient_email'),
                "receiverEmail": formData.get('recipient_mobile'),
            }
        }

        const request = await fetch(`${appSettings.AppBaseURL}/TransferVoucher`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();

        if (response.STATUS) {
            setCustomerShareExist(true);
            alert('Successful');
        } else {
            setCustomerShareExist(false);
        }
    }

    return (
        <AnimatedPage className='overflow-hidden'>
            <div className='position-sticky z-2'>
                <PageTitle name='TRANSFER VOUCHER' />
            </div>
            <div className='h-100 overflow-y-scroll pb-3 bg-wafaa-grey'>
                <form className='px-3 mt-3 position-relative h-90 bg-wafaa-grey' onSubmit={(event) => submitShareForm(event)}>
                    <div className='py-2'>
                        <p className='font-size-13 font-weight-800 text-black'>Recipient Details</p>
                        <div className='bg-white shadow p-3 rounded-3'>
                            <div className='d-flex justify-content-between mb-3'>
                                <label className='col-2 text-wafaa-grey font-size-14'>Email</label>
                                <input className='col-9 border-0 font-size-14 ps-2 text-dark' required name='recipient_email' type='text' placeholder='Enter Email' />
                            </div>
                            <div className='d-flex justify-content-between'>
                                <label className='col-2 text-wafaa-grey font-size-14'>Mobile</label>
                                <input className='col-9 border-0 font-size-14 ps-2 text-dark' required name='recipient_mobile' type='tel' placeholder='Enter Mobile' />
                            </div>
                        </div>
                        <div className={'font-size-12 text-end pt-3 text-danger ' + (customerShareExist ? 'd-none' : 'd-block')}>
                            Customer does not exist
                        </div>
                    </div>

                    <button type="submit" className="bg-wafaa-purple bottom-0 btn mb-2 mt-3 position-absolute rounded-3 text-white w-85">
                        Share Voucher
                    </button>

                </form>
            </div>
        </AnimatedPage>
    );
}

export default TransferVoucher;
