import React from 'react';
import bg_circles from '../../../images/bg-circle.svg';
import back_arrow from '../../../images/icons/material-symbols_arrow-back-ios-new.svg';
import { useNavigate } from "react-router-dom";

function Title({name}) {
    let navigate = useNavigate();

    return (
        <div className='w-100 bg-wafaa-purple h-10 d-flex align-items-center text-white font-size-16' style={{
            backgroundImage: `url(${bg_circles})`
        }}>
            <span className='d-block me-2' onClick={() => navigate(-1)}>
               <img src={back_arrow} alt='Back button iOS' /> 
            </span>
            {name}
            
        </div>
    );
}

export default Title;
