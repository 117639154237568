import React, { useState } from 'react';
import emojis from '../../../images/icons/emojis.svg'
import emojis_1 from '../../../images/icons/emojis_1.svg'
import emojis_2 from '../../../images/icons/emojis_2.svg'
import emojis_3 from '../../../images/icons/emojis_3.svg'
import emojis_4 from '../../../images/icons/emojis_4.svg'

function EmojiFeedback({ rating, ratingOptions }) {

    const [emojiSelected, setEmojiSelected] = useState(null);

    const [emojiText, setEmojiText] = useState("Choose your experience");
    
    const [starSelected, setStarSelected] = useState(-1);
    
    const [starText, setStarText] = useState("Choose your experience");

    const handleFeedbackSelection = (emojiNumber, emojiText) => {
        setEmojiText(emojiText);
        setEmojiSelected(emojiNumber)
    }

    const handleStarFeedbackSelection = (starNumber, startText) => {
        console.log(starNumber);
        setStarText(startText);
        setStarSelected(starNumber)
    }

    return (
        <>
            <div className='py-3'>
                <p className='text-wafaa-black-1 d-flex flex-column font-size-18'>
                    <span>{rating.description}</span>
                    <span>{rating.secondLanguage}</span>
                </p>
                <input type="hidden" name='rating_id[]' value={rating.rating_id ?? ''} />
                <input type="hidden" name='client_id[]' value={rating.Client_id ?? ''} />
                <input type="hidden" name='rating_name[]' value={rating.description ?? ''} />
                <input type="hidden" name='rating_type[]' value={rating.rating_type ?? ''} />
                <input type="hidden" name='star_selected[]' value={starSelected ?? ''} />
                <input type="hidden" name='emoji_selected[]' value={emojiSelected ?? ''} />
                {
                    rating.rating_type === 'Star' ?
                        <>
                            <div className='justify-content-between m-0 p-0 row w-90'>
                                <div className={'cursor-pointer col-2 transition-0-2-bounce ' + (starSelected >= 1 ? ' text-warning' : '')}
                                    onClick={() =>
                                        handleStarFeedbackSelection(
                                            ratingOptions[0].index_num,
                                            `${ratingOptions[0].options_desc} ${ratingOptions[0].options_desc2}`
                                        )
                                    }>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_927_425)">
                                            <path d="M14.5304 1.28332C14.6906 0.845726 15.3094 0.845724 15.4696 1.28331L18.5347 9.66044C18.6045 9.85127 18.7829 9.98087 18.9859 9.9883L27.9002 10.3147C28.3659 10.3318 28.5571 10.9204 28.1904 11.2079L21.1705 16.7117C21.0106 16.837 20.9425 17.0467 20.9981 17.2422L23.4423 25.821C23.57 26.2692 23.0693 26.6329 22.6826 26.373L15.2789 21.3974C15.1102 21.2841 14.8898 21.2841 14.7211 21.3974L7.31741 26.373C6.93067 26.6329 6.42998 26.2692 6.55766 25.821L9.00187 17.2422C9.05755 17.0467 8.98941 16.837 8.8295 16.7117L1.80956 11.2079C1.44286 10.9204 1.63411 10.3318 2.09976 10.3147L11.0141 9.9883C11.2171 9.98087 11.3955 9.85127 11.4653 9.66044L14.5304 1.28332Z" fill="currentColor" />
                                            <path d="M14.7652 1.36922C14.8453 1.15042 15.1547 1.15043 15.2348 1.36922L18.2999 9.74635C18.4046 10.0326 18.6722 10.227 18.9768 10.2381L27.8911 10.5646C28.1239 10.5731 28.2195 10.8674 28.0362 11.0111L21.0162 16.5149C20.7764 16.703 20.6742 17.0175 20.7577 17.3107L23.2019 25.8895C23.2658 26.1136 23.0154 26.2955 22.822 26.1655L15.4183 21.1899C15.1654 21.0199 14.8346 21.0199 14.5817 21.1899L7.17797 26.1655C6.9846 26.2955 6.73425 26.1136 6.79809 25.8895L9.2423 17.3107C9.32582 17.0175 9.22362 16.703 8.98375 16.5149L1.96381 11.0111C1.78046 10.8674 1.87608 10.5731 2.10891 10.5646L11.0232 10.2381C11.3278 10.227 11.5954 10.0326 11.7001 9.74635L14.7652 1.36922Z" stroke="#777777" strokeWidth="0.5" />
                                        </g>
                                    </svg>
                                </div>

                                <div className={'cursor-pointer col-2 transition-0-2-bounce ' + (starSelected >= 2 ? ' text-warning' : '')}
                                    onClick={() =>
                                        handleStarFeedbackSelection(
                                            ratingOptions[1].index_num,
                                            `${ratingOptions[1].options_desc} ${ratingOptions[1].options_desc2}`
                                        )
                                    }>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_927_425)">
                                            <path d="M14.5304 1.28332C14.6906 0.845726 15.3094 0.845724 15.4696 1.28331L18.5347 9.66044C18.6045 9.85127 18.7829 9.98087 18.9859 9.9883L27.9002 10.3147C28.3659 10.3318 28.5571 10.9204 28.1904 11.2079L21.1705 16.7117C21.0106 16.837 20.9425 17.0467 20.9981 17.2422L23.4423 25.821C23.57 26.2692 23.0693 26.6329 22.6826 26.373L15.2789 21.3974C15.1102 21.2841 14.8898 21.2841 14.7211 21.3974L7.31741 26.373C6.93067 26.6329 6.42998 26.2692 6.55766 25.821L9.00187 17.2422C9.05755 17.0467 8.98941 16.837 8.8295 16.7117L1.80956 11.2079C1.44286 10.9204 1.63411 10.3318 2.09976 10.3147L11.0141 9.9883C11.2171 9.98087 11.3955 9.85127 11.4653 9.66044L14.5304 1.28332Z" fill="currentColor" />
                                            <path d="M14.7652 1.36922C14.8453 1.15042 15.1547 1.15043 15.2348 1.36922L18.2999 9.74635C18.4046 10.0326 18.6722 10.227 18.9768 10.2381L27.8911 10.5646C28.1239 10.5731 28.2195 10.8674 28.0362 11.0111L21.0162 16.5149C20.7764 16.703 20.6742 17.0175 20.7577 17.3107L23.2019 25.8895C23.2658 26.1136 23.0154 26.2955 22.822 26.1655L15.4183 21.1899C15.1654 21.0199 14.8346 21.0199 14.5817 21.1899L7.17797 26.1655C6.9846 26.2955 6.73425 26.1136 6.79809 25.8895L9.2423 17.3107C9.32582 17.0175 9.22362 16.703 8.98375 16.5149L1.96381 11.0111C1.78046 10.8674 1.87608 10.5731 2.10891 10.5646L11.0232 10.2381C11.3278 10.227 11.5954 10.0326 11.7001 9.74635L14.7652 1.36922Z" stroke="#777777" strokeWidth="0.5" />
                                        </g>
                                    </svg>
                                </div>

                                <div className={'cursor-pointer col-2 transition-0-2-bounce ' + (starSelected >= 3 ? ' text-warning' : '')}
                                    onClick={() =>
                                        handleStarFeedbackSelection(
                                            ratingOptions[2].index_num,
                                            `${ratingOptions[2].options_desc} ${ratingOptions[2].options_desc2}`
                                        )
                                    }>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_927_425)">
                                            <path d="M14.5304 1.28332C14.6906 0.845726 15.3094 0.845724 15.4696 1.28331L18.5347 9.66044C18.6045 9.85127 18.7829 9.98087 18.9859 9.9883L27.9002 10.3147C28.3659 10.3318 28.5571 10.9204 28.1904 11.2079L21.1705 16.7117C21.0106 16.837 20.9425 17.0467 20.9981 17.2422L23.4423 25.821C23.57 26.2692 23.0693 26.6329 22.6826 26.373L15.2789 21.3974C15.1102 21.2841 14.8898 21.2841 14.7211 21.3974L7.31741 26.373C6.93067 26.6329 6.42998 26.2692 6.55766 25.821L9.00187 17.2422C9.05755 17.0467 8.98941 16.837 8.8295 16.7117L1.80956 11.2079C1.44286 10.9204 1.63411 10.3318 2.09976 10.3147L11.0141 9.9883C11.2171 9.98087 11.3955 9.85127 11.4653 9.66044L14.5304 1.28332Z" fill="currentColor" />
                                            <path d="M14.7652 1.36922C14.8453 1.15042 15.1547 1.15043 15.2348 1.36922L18.2999 9.74635C18.4046 10.0326 18.6722 10.227 18.9768 10.2381L27.8911 10.5646C28.1239 10.5731 28.2195 10.8674 28.0362 11.0111L21.0162 16.5149C20.7764 16.703 20.6742 17.0175 20.7577 17.3107L23.2019 25.8895C23.2658 26.1136 23.0154 26.2955 22.822 26.1655L15.4183 21.1899C15.1654 21.0199 14.8346 21.0199 14.5817 21.1899L7.17797 26.1655C6.9846 26.2955 6.73425 26.1136 6.79809 25.8895L9.2423 17.3107C9.32582 17.0175 9.22362 16.703 8.98375 16.5149L1.96381 11.0111C1.78046 10.8674 1.87608 10.5731 2.10891 10.5646L11.0232 10.2381C11.3278 10.227 11.5954 10.0326 11.7001 9.74635L14.7652 1.36922Z" stroke="#777777" strokeWidth="0.5" />
                                        </g>
                                    </svg>
                                </div>

                                <div className={'cursor-pointer col-2 transition-0-2-bounce ' + (starSelected >= 4 ? ' text-warning' : '')}
                                    onClick={() =>
                                        handleStarFeedbackSelection(
                                            ratingOptions[3].index_num,
                                            `${ratingOptions[3].options_desc} ${ratingOptions[3].options_desc2}`
                                        )
                                    }>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_927_425)">
                                            <path d="M14.5304 1.28332C14.6906 0.845726 15.3094 0.845724 15.4696 1.28331L18.5347 9.66044C18.6045 9.85127 18.7829 9.98087 18.9859 9.9883L27.9002 10.3147C28.3659 10.3318 28.5571 10.9204 28.1904 11.2079L21.1705 16.7117C21.0106 16.837 20.9425 17.0467 20.9981 17.2422L23.4423 25.821C23.57 26.2692 23.0693 26.6329 22.6826 26.373L15.2789 21.3974C15.1102 21.2841 14.8898 21.2841 14.7211 21.3974L7.31741 26.373C6.93067 26.6329 6.42998 26.2692 6.55766 25.821L9.00187 17.2422C9.05755 17.0467 8.98941 16.837 8.8295 16.7117L1.80956 11.2079C1.44286 10.9204 1.63411 10.3318 2.09976 10.3147L11.0141 9.9883C11.2171 9.98087 11.3955 9.85127 11.4653 9.66044L14.5304 1.28332Z" fill="currentColor" />
                                            <path d="M14.7652 1.36922C14.8453 1.15042 15.1547 1.15043 15.2348 1.36922L18.2999 9.74635C18.4046 10.0326 18.6722 10.227 18.9768 10.2381L27.8911 10.5646C28.1239 10.5731 28.2195 10.8674 28.0362 11.0111L21.0162 16.5149C20.7764 16.703 20.6742 17.0175 20.7577 17.3107L23.2019 25.8895C23.2658 26.1136 23.0154 26.2955 22.822 26.1655L15.4183 21.1899C15.1654 21.0199 14.8346 21.0199 14.5817 21.1899L7.17797 26.1655C6.9846 26.2955 6.73425 26.1136 6.79809 25.8895L9.2423 17.3107C9.32582 17.0175 9.22362 16.703 8.98375 16.5149L1.96381 11.0111C1.78046 10.8674 1.87608 10.5731 2.10891 10.5646L11.0232 10.2381C11.3278 10.227 11.5954 10.0326 11.7001 9.74635L14.7652 1.36922Z" stroke="#777777" strokeWidth="0.5" />
                                        </g>
                                    </svg>
                                </div>

                                <div className={'cursor-pointer col-2 transition-0-2-bounce ' + (starSelected >= 5 ? ' text-warning' : '')}
                                    onClick={() =>
                                        handleStarFeedbackSelection(
                                            ratingOptions[4].index_num,
                                            `${ratingOptions[4].options_desc} ${ratingOptions[4].options_desc2}`
                                        )
                                    }>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_927_425)">
                                            <path d="M14.5304 1.28332C14.6906 0.845726 15.3094 0.845724 15.4696 1.28331L18.5347 9.66044C18.6045 9.85127 18.7829 9.98087 18.9859 9.9883L27.9002 10.3147C28.3659 10.3318 28.5571 10.9204 28.1904 11.2079L21.1705 16.7117C21.0106 16.837 20.9425 17.0467 20.9981 17.2422L23.4423 25.821C23.57 26.2692 23.0693 26.6329 22.6826 26.373L15.2789 21.3974C15.1102 21.2841 14.8898 21.2841 14.7211 21.3974L7.31741 26.373C6.93067 26.6329 6.42998 26.2692 6.55766 25.821L9.00187 17.2422C9.05755 17.0467 8.98941 16.837 8.8295 16.7117L1.80956 11.2079C1.44286 10.9204 1.63411 10.3318 2.09976 10.3147L11.0141 9.9883C11.2171 9.98087 11.3955 9.85127 11.4653 9.66044L14.5304 1.28332Z" fill="currentColor" />
                                            <path d="M14.7652 1.36922C14.8453 1.15042 15.1547 1.15043 15.2348 1.36922L18.2999 9.74635C18.4046 10.0326 18.6722 10.227 18.9768 10.2381L27.8911 10.5646C28.1239 10.5731 28.2195 10.8674 28.0362 11.0111L21.0162 16.5149C20.7764 16.703 20.6742 17.0175 20.7577 17.3107L23.2019 25.8895C23.2658 26.1136 23.0154 26.2955 22.822 26.1655L15.4183 21.1899C15.1654 21.0199 14.8346 21.0199 14.5817 21.1899L7.17797 26.1655C6.9846 26.2955 6.73425 26.1136 6.79809 25.8895L9.2423 17.3107C9.32582 17.0175 9.22362 16.703 8.98375 16.5149L1.96381 11.0111C1.78046 10.8674 1.87608 10.5731 2.10891 10.5646L11.0232 10.2381C11.3278 10.227 11.5954 10.0326 11.7001 9.74635L14.7652 1.36922Z" stroke="#777777" strokeWidth="0.5" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <i className='text-wafaa-black-1'>{starText}</i>
                        </>
                        :
                        <>
                            <div className='justify-content-between m-0 p-0 row w-90'>
                                <img
                                    className={'col-2 p-0 img-fluid transition-0-2-bounce ' + (emojiSelected === ratingOptions[0].options_id ? 'opacity-100 transform-scale-1-2' : 'opacity-25')}
                                    onClick={() =>
                                        handleFeedbackSelection(
                                            ratingOptions[0].options_id,
                                            `${ratingOptions[0].options_desc} ${ratingOptions[0].options_desc2}`
                                        )
                                    } src={emojis} alt='Emoji' />
                                <img
                                    className={'col-2 p-0 img-fluid transition-0-2-bounce ' + (emojiSelected === ratingOptions[1].options_id ? 'opacity-100 transform-scale-1-2' : 'opacity-25')}
                                    onClick={() =>
                                        handleFeedbackSelection(
                                            ratingOptions[1].options_id,
                                            `${ratingOptions[1].options_desc} ${ratingOptions[1].options_desc2}`
                                        )
                                    } src={emojis_1} alt='Emoji' />
                                <img
                                    className={'col-2 p-0 img-fluid transition-0-2-bounce ' + (emojiSelected === ratingOptions[2].options_id ? 'opacity-100 transform-scale-1-2' : 'opacity-25')}
                                    onClick={() =>
                                        handleFeedbackSelection(
                                            ratingOptions[2].options_id,
                                            `${ratingOptions[2].options_desc} ${ratingOptions[2].options_desc2}`
                                        )
                                    } src={emojis_2} alt='Emoji' />
                                <img
                                    className={'col-2 p-0 img-fluid transition-0-2-bounce ' + (emojiSelected === ratingOptions[3].options_id ? 'opacity-100 transform-scale-1-2' : 'opacity-25')}
                                    onClick={() =>
                                        handleFeedbackSelection(
                                            ratingOptions[3].options_id,
                                            `${ratingOptions[3].options_desc} ${ratingOptions[3].options_desc2}`
                                        )
                                    } src={emojis_3} alt='Emoji' />
                                <img
                                    className={'col-2 p-0 img-fluid transition-0-2-bounce ' + (emojiSelected === ratingOptions[4].options_id ? 'opacity-100 transform-scale-1-2' : 'opacity-25')}
                                    onClick={() =>
                                        handleFeedbackSelection(
                                            ratingOptions[4].options_id,
                                            `${ratingOptions[4].options_desc} ${ratingOptions[4].options_desc2}`
                                        )
                                    } src={emojis_4} alt='Emoji' />
                            </div>
                            <i className='text-wafaa-black-1'>{emojiText}</i>
                        </>
                }
                <div className='my-3 w-90 d-none'>
                        <textarea name='other_feedback[]' placeholder='Suggest anything we can improve' className='shadow border p-2 rounded-3 w-100' />
                    </div>
            </div>
        </>
    )
}

export default EmojiFeedback;