import { useState } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import Title from './misc/Title';
import { useNavigate } from 'react-router-dom';
import Alert from './Alert/Alert';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import useAlert from "../../hooks/Alert";
import { useAuth } from '../../context/userContext';

function Login({ baseURL, clientID }) {
    const { setAuthUser } = useAuth();
    const { showAlert, setShowAlert, alertStyle, setAlertStyle, alertMessage, setAlertMessage, hideAlert } = useAlert();
    
    // State to toggle between email and mobile login
    const [useMobile, setUseMobile] = useState(true); // Default is mobile
    const [loginValue, setLoginValue] = useState(''); // State to hold input value

    function setInputHeight(event) {
        if (event.type === 'focus') {
            event.target.classList.add('mt-4');
        }
        if (event.type === 'blur') {
            if (event.target.value.trim() === '') {
                event.target.classList.remove('mt-4');
            }
        }
    }

    const navigate = useNavigate();

    async function sendLogin(event, baseURL, closure) {
        const formData = new FormData(event.target.form);
        const loginData = useMobile ? formData.get('mobile') : formData.get('email');
        const payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "LoyLogin",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "USERNAME": loginData, // Send either mobile or email
                "PWORD": formData.get('password'),
                "TYPE_NAME": useMobile ? "PHONE" : "EMAIL",
                "LOC_ID": "001",
                "CLIENT_ID": clientID
            }
        };

        if (event.target.form.checkValidity()) {
            const request = await fetch(`${baseURL}/LoyLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payLoad)
            })

            try {
                const response = await request.json();
                if (response.STATUS) {
                    setAlertStyle({
                        background: 'linear-gradient(to right, #32bb71 0%, #2a9d8f 100%)'
                    });
                    setAlertMessage({
                        success: true,
                        message: 'Successfully logged in',
                        mode: 'Login'
                    });

                    setShowAlert('d-block');

                    Cookies.set('wafaa-user-data', JSON.stringify(response), { expires: 30 });

                    setAuthUser(response);

                    setTimeout(() => {
                        closure('/home');
                    }, 1500);
                } else {
                    setAlertStyle({
                        background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                    });
                    setAlertMessage({
                        success: false,
                        message: response.MESSAGE,
                        mode: 'Login'
                    });
                    setShowAlert('d-block');
                }
            } catch (error) {
                setAlertStyle({
                    background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                });
                setAlertMessage({
                    success: false,
                    message: 'Failed to login',
                    mode: 'Login'
                });
                setShowAlert('d-block');
            }
        } else {
            setAlertStyle({
                background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
            });
            setAlertMessage({
                success: false,
                message: 'Failed to login. Inputs not valid',
                mode: 'Login'
            });
            setShowAlert('d-block');
        }
    }

    // Function to handle toggling between mobile and email
    function toggleLoginMethod(isMobile) {
        setUseMobile(isMobile);
        setLoginValue(''); // Clear the input field value when toggling
    }

    return (
        <AnimatedPage className='h-100 overflow-hidden'>
            <div className='h-100 bg-white overflow-hidden'>
                <Title name="LOG IN" />
                <Alert showAlert={showAlert} alertStyle={alertStyle} alertMessage={alertMessage} hideAlert={hideAlert} />
                <div className='h-90'>
                    <form className='bg-white h-100' autoComplete='off'>
                        <div className='h-100 w-85 mx-auto bg-white'>
                            <div className='mt-3 font-size-12'>
                                {/* Toggle Button */}

                                <div className="text-center my-4 d-flex justify-content-center">
                                    <style>
                                        {`
                                        .toggle-btn-container {
                                            position: relative;
                                            display: flex;
                                            border-radius: 6px;
                                            overflow: hidden;
                                            width: fit-content;
                                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                                            background-color: #f0f0f0;
                                        }
                                        .toggle-btn-container::before {
                                            content: '';
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 50%;
                                            height: 100%;
                                            background-color: white;
                                            border-radius: 6px;
                                            z-index: 0;
                                            transition: transform 0.2s ease-in-out;
                                            transform: translateX(${useMobile ? '100%' : '0%'});
                                        }
                                        .toggle-btn {
                                            position: relative;
                                            padding: 8px 40px;
                                            font-size: 12px;
                                            font-weight: bold;
                                            border: none;
                                            outline: none;
                                            cursor: pointer;
                                            background: none;
                                            z-index: 1;
                                            color: #555;
                                            transition: color 0.3s ease;
                                        }
                                        .toggle-btn-active {
                                            color: black;
                                        }
                                        `}
                                    </style>

                                    <div className="toggle-btn-container">
                                        <button
                                        type="button"
                                        className={`toggle-btn ${!useMobile ? 'toggle-btn-active' : ''}`}
                                        onClick={() => toggleLoginMethod(false)}
                                        >
                                        Email
                                        </button>
                                        <button
                                        type="button"
                                        className={`toggle-btn ${useMobile ? 'toggle-btn-active' : ''}`}
                                        onClick={() => toggleLoginMethod(true)}
                                        >
                                        Mobile
                                        </button>
                                    </div>
                            </div>

                                {/* Conditionally render Mobile or Email field */}
                                {useMobile ? (
                                    <div className='form-group position-relative'>
                                        <label className='text-wafaa-grey position-absolute bg-white z-0'>Mobile*</label>
                                        <input 
                                            className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' 
                                            onFocus={(event) => setInputHeight(event)} 
                                            onBlur={(event) => setInputHeight(event)} 
                                            type='tel'  
                                            minLength="3" 
                                            maxLength="15" 
                                            name='mobile' 
                                            value={loginValue}
                                            onChange={(e) => setLoginValue(e.target.value)}
                                            required 
                                        />
                                    </div>
                                ) : (
                                    <div className='form-group position-relative'>
                                        <label className='text-wafaa-grey position-absolute bg-white z-0'>Email*</label>
                                        <input 
                                            className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' 
                                            onFocus={(event) => setInputHeight(event)} 
                                            onBlur={(event) => setInputHeight(event)} 
                                            type='text' 
                                            maxLength="50"  
                                            name='email' 
                                            value={loginValue}
                                            onChange={(e) => setLoginValue(e.target.value)}
                                            required 
                                        />
                                    </div>
                                )}
                                
                                <div className='form-group position-relative mt-4'>
                                    <label className='text-wafaa-grey position-absolute bg-white z-0'>Password*</label>
                                    <input 
                                        className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' 
                                        onFocus={(event) => setInputHeight(event)} 
                                        onBlur={(event) => setInputHeight(event)} 
                                        type='password' 
                                        name='password' 
                                        maxLength="15" 
                                        required 
                                    />
                                </div>
                                <Link to='/forgot-password' className='d-block text-wafaa-purple text-decoration-none my-3 text-center'>Forgot Password</Link>
                            </div>

                            <div className='text-center position-absolute bottom-10 bg-white w-85 height-50 pt-2 max-width-700'>
                                <button onClick={async (e) => await sendLogin(e, baseURL, navigate)} type='button' className='w-100 btn btn-lg py-2-5 btn-primary bg-wafaa-purple border-wafaa-purple shadow-wafaa-btn font-size-12'>
                                    Log In
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AnimatedPage>
    );
}

export default Login;
