import { Link } from "react-router-dom";
import moment from 'moment';

export function TableRow({ transaction }) {
    return (
        <Link className="text-decoration-none bg-transparent" to={ '/transactions/single/' + transaction.BILLREF} state={transaction}>
        <div className='row px-2 border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1 bg-transparent'>
            <div className='text-center col-4 font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-1'>
                { transaction.ITEM_DETAILS.length < 1 ? '' : moment(transaction.ITEM_DETAILS[0].TRAN_DATE).format('DD.MM.YYYY') }
            </div>
            <div className={ 'text-center col-4 transition-0-5-ease font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-1 ' }>
                AED { transaction.BILL_AMOUNT }
            </div>
            <div className={ 'text-center col-4 transition-0-5-ease font-size-13 text-wafaa-bright-green bg-transparent ps-0 pe-3 py-1 ' }>
                { transaction.POINTS_EARNED.length ? transaction.POINTS_EARNED[0].POINTS_EARNED : 0 }
            </div>
        </div>
        </Link>
    )
}

export function TableRowRedemption({ transaction }) {
    return (
        <Link className="text-decoration-none bg-transparent" to={ '/transactions/single/redemption/' + transaction.BILLREF}>
        <div className='row px-2 border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1 bg-transparent'>
            <div className='text-center col-4 font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-1'>
                { transaction.REEDEM_DATE ? moment(transaction.REEDEM_DATE).format('DD.MM.YYYY') : '' }
            </div>
            <div className={ 'text-center col-4 transition-0-5-ease font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-1 ' }>
                AED { transaction.VALUE }
            </div>
            <div className={ 'text-center col-4 transition-0-5-ease font-size-13 text-wafaa-bright-green bg-transparent ps-0 pe-3 py-1 ' }>
                { transaction.POINTS_REDEMEED ? transaction.POINTS_REDEMEED : '' }
            </div>
        </div>
        </Link>
    )
}