import { createContext, useContext, useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const userCookieName = 'wafaa-user-data';

const UserContext = createContext();

export function useAuth() {
    return useContext(UserContext);
}

export function UserProvider(props) {
    const [authUser, setAuthUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const location = useLocation();

    const navigate = useNavigate();

    const authGuardRoutes = ['/home', '/offers', '/history', '/transactions', '/digital-receipt', '/profile', '/my-profile'];
    const nonAuthGuardRoutes = ['/', '/login', '/join'];

    useEffect(() => {
        const userCookie = Cookies.get(userCookieName)

        const authRouteMatch = authGuardRoutes.findIndex((route) => route.includes(location.pathname));
        const nonAuthRouteMatch = nonAuthGuardRoutes.findIndex((route) => route.includes(location.pathname));
        if (!userCookie) {
            if(authRouteMatch > -1){
                navigate('/')
            }
        }else{
            const userData = JSON.parse(userCookie);
            setAuthUser(userData);
            setIsLoggedIn(true);
            if(nonAuthRouteMatch > -1){
                navigate('/home')
            }
        }
    }, [])

    const authenticationState = {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    }

    return (
        <UserContext.Provider value={authenticationState}>
            {props.children}
        </UserContext.Provider>
    )
}