import { useEffect, useState } from "react";
import ImageCarousel from "./ImageCarousel";
import SkeletonLoader from "./SkeletonLoader";

const getMoreImages = async (clientID, promoCode, promoId) => {
    try {
        const response = await fetch("https://devp.mycomsys.com:8807/api/v2/customer/Loy_CRM_DownloadPromotionImages", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "DATA": {
                    "CLIENT_ID": clientID,
                    "PROMO_CODE": promoCode,
                    "PROMO_ID": promoId,
                },
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to fetch images");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching images:", error);
        throw error;
    }
};

const PromotionImageCarousel = ({ clientID, promoCode, promoId, AdminFileSrvURL }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                setLoading(true);
                const result = await getMoreImages(clientID, promoCode, promoId);
                console.log(result.DATA, 'images');
                setImages(result?.DATA || []); // Assuming result contains an `images` array
            } catch (error) {
                setError("Failed to load images");
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, [clientID, promoCode, promoId]);

    return (
        <div>
            {loading && <SkeletonLoader />}
            {error && <p>{error}</p>}
            {!loading && !error && (
                <ImageCarousel
                    images={images}
                    imageURL={AdminFileSrvURL}
                />
            )}
        </div>
    );
};

export default PromotionImageCarousel;
