import { useRef } from "react";
import useAlert from "../../../hooks/Alert";
import calendar_icon from "../../../images/icons/bi_calendar-date.svg";
import Alert from "../../Auth/Alert/Alert";
import getImgNameFromURL from "../../../Utils/getImgNameFromURL"

function GenderModal({
    setCustomerData,
    customerData,
    customerBirthDate,
    modalID,
    baseURL,
    setInputHeight,
}) {
    const {
        showAlert,
        setShowAlert,
        alertStyle,
        setAlertStyle,
        alertMessage,
        setAlertMessage,
        hideAlert,
    } = useAlert();

    const closeButton = useRef();

    const dateInput = useRef();
    const dateMaskInput = useRef();

    function openDate() {
        dateInput.current.showPicker();
        dateInput.current.focus();
    }

    function updateInputMask(event) {
        if (event.target.value === "") {
            dateMaskInput.current.classList.remove("mt-4");
        } else {
            dateMaskInput.current.value = event.target.value;
            dateMaskInput.current.classList.add("mt-4");
        }
    }

    // Function to calculate the age
    function calculateAge(birthDate) {
        const today = new Date();
        const dob = new Date(birthDate);
        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();

        if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < dob.getDate())
        ) {
            age--;
        }
        return age;
    }

    async function update(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const birthDate = formData.get("date_of_birth");

        // Validate if the age is 18 or older
        const age = calculateAge(birthDate);
        if (age < 18) {
            hideAlert();

            setAlertStyle({
                background:
                    "linear-gradient(to right, #f6743e 0%, #d42525 100%)",
            });
            setAlertMessage({
                success: false,
                message: "You must be at least 18 years old to register",
                mode: "Registration",
            });
            setShowAlert("d-block");
            setTimeout(() => {
                hideAlert();
            }, 3000);

            return; // Stop further execution if age is less than 18
        }

        const payLoad = {
            DIV_DES: "",
            DIV_ID: "1",
            FUNCTION: "LoyUpdateCustomer",
            SEND_KEY: "123456",
            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                TYPE_NAME: "CUSTOMER_UPDATE",
                CUSTOMER_DETAILS: {
                    CUST_CODE: customerData.CUST_CODE,
                    FIRSTNAME: customerData.CUST_NAME.split(" ")[0],
                    LASTNAME: customerData.CUST_NAME.split(" ")[1],
                    MOBILE: customerData.MOBILE,
                    EMAIL: customerData.EMAIL,
                    ADDR1: customerData.ADDR1,
                    ADDR2: customerData.ADDR2,
                    ADDR3: customerData.ADDR3,
                    BIRTHDATE: birthDate,
                    COMPNAME: customerData.COMP_NAME,
                    NATIONALITY: customerData.NATIONALITY,
                    GENDER: customerData.GENDER,
                    country_code: customerData.country_code,
                    city_code: customerData.city_code,
                    area_code: customerData.area_code,
                    CITY: customerData.CITY,
                    AREA: customerData.AREA,
                    COUNTRY: customerData.COUNTRY,
                    profile_img: getImgNameFromURL(customerData.PROFILE_IMAGE)
                },
            },
        };

        const request = await fetch(`${baseURL}/LoyUpdateCustomer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payLoad),
        });

        const response = await request.json();

        if (response.STATUS) {
            customerData.BIRTH_DATE = birthDate;
            setCustomerData({ ...customerData });
            closeButton.current?.click();
        } else {
            closeButton.current?.click();
            alert("Failed to update");
        }
    }

    return (
        <>
            <div
                className="modal fade max-width-700 start-50"
                style={{
                    transform: "translateX(-50%)",
                }}
                id={modalID}
                aria-hidden="true"
                tabIndex="-1"
            >
                <Alert
                    showAlert={showAlert}
                    alertStyle={alertStyle}
                    alertMessage={alertMessage}
                    hideAlert={hideAlert}
                />
                <div className="modal-dialog modal-dialog-centered modal-fullscreen max-width-700">
                    <div className="modal-content">
                        <div className="modal-header shadow bg-wafaa-white-2">
                            <h5 className="modal-title text-wafaa-black-1">
                                Change Date of Birth
                            </h5>
                            <button
                                type="button"
                                className="text-wafaa-black-1 bg-transparent border-0"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{
                                    transform: "scale(1.8)",
                                }}
                                ref={closeButton}
                            >
                                &times;
                            </button>
                        </div>
                        <form
                            onSubmit={(event) => {
                                update(event);
                            }}
                            className="h-100 d-flex flex-column"
                        >
                            <div className="modal-body bg-wafaa-white-2">
                                <div className="form-group position-relative mt-4">
                                    <img
                                        src={calendar_icon}
                                        alt="Calendar icon"
                                        className="position-absolute right-0"
                                    />
                                    <label className="text-wafaa-black-1 position-absolute bg-wafaa-white-2 z-0">
                                        Date of birth
                                    </label>
                                    <input
                                        readOnly
                                        ref={dateMaskInput}
                                        className={
                                            "font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1 text-wafaa-black-1 " +
                                            (customerBirthDate !== ""
                                                ? "mt-4"
                                                : "")
                                        }
                                        onClick={(event) => {
                                            setInputHeight(event, true);
                                            openDate();
                                        }}
                                        onBlur={(event) =>
                                            setInputHeight(event)
                                        }
                                        type="text"
                                        defaultValue={customerBirthDate}
                                    />
                                    <input
                                        className="height-0 left-0 opacity-0 position-absolute z-n1"
                                        ref={dateInput}
                                        onChange={(event) =>
                                            updateInputMask(event)
                                        }
                                        type="date"
                                        name="date_of_birth"
                                        defaultValue={customerBirthDate}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer bg-wafaa-white-2">
                                <button className="w-100 btn btn-lg py-2-5 btn-primary bg-wafaa-purple-2 border-wafaa-purple shadow-wafaa-btn font-size-12">
                                    DONE
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GenderModal;
