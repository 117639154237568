import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import ice_cream_logo from '../../images/ice_cream_logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import moment from 'moment';

function SingleTransaction({ baseURL, clientID, AppDisplayName }) {

    const location = useLocation();

    const navigate = useNavigate();

    const transactionHistory = location.state;

    const [voucherIssued, setVoucherIssued] = useState('No');

    const [voucherRedeemed, setVoucherRedeemed] = useState({});

    useEffect(() => {
        if(!transactionHistory){
            navigate('/history');
        }else{
            if(transactionHistory.VOUCHER_ISSUED.length > 0){
                setVoucherIssued('Yes')
            }

            if(transactionHistory.VOUCHER_REDEEMED.length > 0){
                setVoucherRedeemed(transactionHistory.VOUCHER_REDEEMED[0])
            }
        }
    }, [])

    return (
        <AnimatedPage>
            <div className='position-sticky z-2'>
                <PageTitle name='TRANSACTION DETAILS' />
            </div>
            <div className='w-100 min-vh-100 overflow-y-scroll pb-5 bg-wafaa-white-3'>

                <div className='w-90 mx-auto pb-5 mt-3'>
                    <div className='height-70 width-70 rounded-4 overflow-hidden d-none'>
                        <img src={ice_cream_logo} alt='Ice cream logo' className='w-100 h-100' />
                    </div>
                    <table className='table table-borderless pb-5 table-fixed mt-4'>
                        <thead>
                            <tr>
                                <th className='bg-transparent font-size-16 text-wafaa-dark-blue-2'></th>
                                <th className='bg-transparent font-size-14 text-wafaa-bright-green text-end'>+{transactionHistory && transactionHistory.POINTS_EARNED ? transactionHistory.POINTS_EARNED[0].POINTS_EARNED : '0'} points</th>
                            </tr>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1 d-none">
                                <th className='bg-transparent font-size-13 font-weight-400 text-wafaa-dark-blue-2'>Burjuman Centre</th>
                                <th className='bg-transparent font-size-14 font-weight-400 text-wafaa-grey text-end'>1.02 AED</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">{AppDisplayName ? AppDisplayName : "True Value"} ID Scan</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{transactionHistory && transactionHistory.ITEM_DETAILS ? moment(transactionHistory.ITEM_DETAILS[0].TRAN_DATE).format('DD MM YYYY, hh:mm:ss a') : ''}</td>
                            </tr>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">Used Coupon</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{transactionHistory.VOUCHER_REDEEMED.length ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">Issued Coupon</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{voucherIssued}</td>
                            </tr>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">Total Paid</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{transactionHistory.BILL_AMOUNT } AED</td>
                            </tr>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">{AppDisplayName ? AppDisplayName : "TRUE VALUE"} ID</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{transactionHistory && transactionHistory.POINTS_EARNED ? transactionHistory.POINTS_EARNED[0].CUSTOMER_CODE : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='position-absolute bottom-15 text-center w-100 d-none'>
                    <Link to='/digital-receipt' className='btn col-11 font-size-14 bg-wafaa-purple-2 text-wafaa-white-2 rounded-4 py-3'>
                        Generate Digital Receipt
                    </Link>
                </div>
            </div>

        </AnimatedPage>
    )
}
export default SingleTransaction;