import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';

function SingleAboutItemPage({ store }) {
    const location = useLocation();
    const navigate = useNavigate();

    const aboutDetails = location.state;

    useEffect(() => {
        if (!aboutDetails) {
            navigate('/about');
        }
    }, [aboutDetails, navigate]);

    return (
        <AnimatedPage className='overflow-hidden bg-wafaa-white-2'>
            <div className='position-sticky z-2'>
                <PageTitle name={aboutDetails.Heading} />
            </div>
            <div className='font-size-13 min-vh-100 p-2 bg-wafaa-white-2 text-wafaa-black-1'>
                {parseContent(aboutDetails.Content)}
            </div>
        </AnimatedPage>
    );
}

export default SingleAboutItemPage;




const parseContent = (content) => {
    const elements = [];
    const regex = /(\*\*(.*?)\*\*)|(\{size:(\d+)\}(.*?)\{size:end\})|(\r?\n)/g; // Updated regex

    let lastIndex = 0;
    let match;

    while ((match = regex.exec(content))) {
        // Add text before the current match
        if (lastIndex < match.index) {
            elements.push(<span key={lastIndex}>{content.slice(lastIndex, match.index)}</span>);
        }

        // Handle bold text
        if (match[2]) {
            elements.push(<span key={match.index} style={{ fontWeight: 'bold' }}>{match[2]}</span>);
        } 
        // Handle font size
        else if (match[4]) {
            elements.push(<span key={match.index} style={{ fontSize: `${match[4]}px` }}>{match[5]}</span>);
        } 
        // Handle new line
        else if (match[6]) {
            elements.push(<br key={match.index} />); // Insert a line break
        }

        lastIndex = regex.lastIndex;
    }

    // Add remaining text
    if (lastIndex < content.length) {
        elements.push(<span key={lastIndex}>{content.slice(lastIndex)}</span>);
    }

    return elements;
};
