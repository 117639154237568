// This optional code is used to register a service worker.
// Learn more about service workers: https://bit.ly/CRA-PWA
export function register(config) {
  if ('serviceWorker' in navigator) {
      window.addEventListener('load', async () => {
          try {
              const registration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/custom-service-worker.js`);
              console.log('Service Worker registered with scope:', registration.scope, 'sw');

              // Subscribe to push notifications
              subscribeToPushNotifications(registration);
              
              registration.onupdatefound = () => {
                  const installingWorker = registration.installing;
                  if (installingWorker) {
                      installingWorker.onstatechange = () => {
                          if (installingWorker.state === 'installed') {
                              if (navigator.serviceWorker.controller) {
                                  console.log('New content is available; please refresh.', 'sw');
                                  if (config && config.onUpdate) {
                                      config.onUpdate(registration);
                                  }
                              } else {
                                  console.log('Content is cached for offline use.', 'sw');
                                  if (config && config.onSuccess) {
                                      config.onSuccess(registration);
                                  }
                              }
                          }
                      };
                  }
              };
          } catch (error) {
              console.log('Service Worker registration failed:', error, 'sw');
          }
      });
  }
}

// Subscribe to Push Notifications
// const subscribeToPushNotifications = async (registration) => {
//   // Check if notifications are supported
//   if ('Notification' in window && 'serviceWorker' in navigator) {
//       try {
//           // Ask for permission to send notifications
//           const permission = await Notification.requestPermission();

//           if (permission === 'granted') {
//               // User has granted permission, proceed to subscribe
//               const subscription = await registration.pushManager.subscribe({
//                   userVisibleOnly: true,
//                   applicationServerKey: '<YOUR_VAPID_PUBLIC_KEY>' // Replace with your VAPID public key
//               });
//               console.log('User is subscribed to push notifications:', subscription);
//               // Send the subscription object to your server to save it
//           } else {
//               console.log('Notification permission denied.');
//           }
//       } catch (error) {
//           console.error('Failed to subscribe the user:', error);
//       }
//   } else {
//       console.warn('Push notifications are not supported in this browser.');
//   }
// };

const subscribeToPushNotifications = async (registration) => {
  // Check if notifications are supported
  if ('Notification' in window && 'serviceWorker' in navigator) {
      try {
          // Ask for permission to send notifications
          const permission = await Notification.requestPermission();

          if (permission === 'granted') {
              console.log('Notification permission granted. You can now subscribe to push notifications.');

              // Commenting out the subscription part for testing
              // const subscription = await registration.pushManager.subscribe({
              //     userVisibleOnly: true,
              //     applicationServerKey: '<YOUR_VAPID_PUBLIC_KEY>' // Replace with your VAPID public key
              // });
              // console.log('User is subscribed to push notifications:', subscription);
              // Send the subscription object to your server to save it
          } else {
              console.log('Notification permission denied.');
          }
      } catch (error) {
          console.error('Failed to request notification permission:', error);
      }
  } else {
      console.warn('Push notifications are not supported in this browser.');
  }
};



export function unregister() {
  if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
          .then(registration => {
              registration.unregister();
          })
          .catch(error => {
              console.error(error.message);
          });
  }
}

// Function to check if service worker is valid
function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, {
      headers: { 'Service-Worker': 'script' }
  })
  .then(response => {
      const contentType = response.headers.get('content-type');
      if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
          navigator.serviceWorker.ready.then(registration => {
              registration.unregister().then(() => {
                  window.location.reload();
              });
          });
      } else {
          register(swUrl, config);
      }
  })
  .catch(() => {
      console.log('No internet connection found. App is running in offline mode.', 'sw');
  });
}
