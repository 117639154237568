import { useEffect, useRef, useState } from "react";
import getImgNameFromURL from "../../../Utils/getImgNameFromURL";
import search from "../../../images/search.svg";

function AreaModal({
    setCustomerData,
    customerData,
    modalID,
    baseURL,
    appSettings,
}) {
    const closeButton = useRef();
    const [areas, setAreas] = useState([]);
    const [filteredAreas, setFilteredAreas] = useState([]); // For filtered areas
    const [areaCode, setAreaCode] = useState(customerData.area_code);
    const [getArea, setGetArea] = useState(customerData.area_name || "");
    const [searchQuery, setSearchQuery] = useState(""); // For search input

    useEffect(() => {
        getAreas();
    }, [customerData]);

    useEffect(() => {
        filterAreas(searchQuery); // Filter areas when searchQuery changes
    }, [searchQuery, areas]);

    async function getAreas() {
        if (customerData.country_code === "" || customerData.city_code === "") return;

        // Reset areas and filteredAreas before fetching new data
        setAreas([]);
        setFilteredAreas([]);

        const payLoad = {
            DATA: {
                client_id: 80623,
                data_type: "M_AREA",
                country_code: customerData.country_code,
                city_code: customerData.city_code,
            },
        };

        try {
            const request = await fetch(
                `${appSettings.AppBaseURL1}CRM_DownloadCou_Ci_Ar`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payLoad),
                }
            );

            const response = await request.json();
            if (response.DATA) {
                // Filter out duplicates
                const uniqueAreas = Array.from(new Set(response.DATA.map(a => a.area_code)))
                    .map(code => response.DATA.find(a => a.area_code === code));

                setAreas(uniqueAreas);
                setFilteredAreas(uniqueAreas); // Set initially unfiltered
            }
        } catch (error) {
            console.error(error);
            alert("Cannot get areas");
        }
    }

    function filterAreas(query) {
        if (!query) {
            setFilteredAreas(areas);
            return;
        }
        const filtered = areas.filter((area) =>
            area.area_name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredAreas(filtered);
    }

    async function update(event) {
        event.preventDefault();

        const payLoad = {
            DIV_DES: "",
            DIV_ID: "1",
            FUNCTION: "LoyUpdateCustomer",
            SEND_KEY: "123456",
            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                TYPE_NAME: "CUSTOMER_UPDATE",
                CUSTOMER_DETAILS: {
                    CUST_CODE: customerData.CUST_CODE,
                    FIRSTNAME: customerData.CUST_NAME.split(" ")[0],
                    LASTNAME: customerData.CUST_NAME.split(" ")[1],
                    MOBILE: customerData.MOBILE,
                    EMAIL: customerData.EMAIL,
                    ADDR1: customerData.ADDR1,
                    ADDR2: customerData.ADDR2,
                    ADDR3: customerData.ADDR3,
                    BIRTHDATE: customerData.BIRTH_DATE,
                    COMPNAME: customerData.COMP_NAME,
                    NATIONALITY: customerData.NATIONALITY,
                    GENDER: customerData.GENDER,
                    country_code: customerData.country_code,
                    city_code: customerData.city_code,
                    area_code: areaCode,
                    CITY: customerData.CITY,
                    AREA: getArea,
                    COUNTRY: customerData.COUNTRY,
                    profile_img: getImgNameFromURL(customerData.PROFILE_IMAGE),
                },
            },
        };

        try {
            const request = await fetch(`${baseURL}/LoyUpdateCustomer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payLoad),
            });

            const response = await request.json();
            if (response.STATUS) {
                customerData.AREA = getArea;
                customerData.area_code = areaCode;
                setCustomerData({ ...customerData });
                closeButton.current?.click();
            } else {
                closeButton.current?.click();
                alert("Failed to update");
            }
        } catch (error) {
            console.error(error);
            alert("Error updating customer");
        }
    }

    return (
        <>
            <div
                className="modal fade max-width-700 start-50"
                style={{ transform: "translateX(-50%)" }}
                id={modalID}
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-fullscreen max-width-700">
                    <div className="modal-content">
                        <div className="modal-header shadow bg-wafaa-white-2">
                            <h5 className="modal-title text-wafaa-black-1">
                                Change Area
                            </h5>
                            <button
                                type="button"
                                className="text-wafaa-black-1 bg-transparent border-0"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{ transform: "scale(1.8)" }}
                                ref={closeButton}
                            >
                                &times;
                            </button>
                        </div>
                        <form onSubmit={update} className="h-100 d-flex flex-column">
                            <div className="h-80 overflow-y-scroll pb-4 bg-wafaa-white-2">
                                <div className="w-100 d-flex justify-content-center position-relative">
                                    <div style={{ position: "relative", width: "90%" }}>
                                        <input
                                            className="w-100 transition-0-2-ease-in position-relative z-1"
                                            type="text"
                                            // placeholder="Search Area"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            style={{
                                                paddingLeft: "38px", // Padding for the icon
                                                paddingBlock: "10px",
                                                margin: "10px 0",
                                                background: "transparent",
                                                borderRadius: "8px",
                                                border: `${searchQuery ? "2px" : "1px"} solid ${searchQuery ? "#182596" : "#ccc"}`,
                                                transition: "border-color 0.2s ease-in-out",
                                                width: "100%",
                                            }}
                                        />
                                        <span
                                            className="position-absolute"
                                            style={{
                                                left: "10px",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                pointerEvents: "none",
                                                color: "#999",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <img src={search} alt='search' width={"25px"} height={"25px"} style={{ opacity: "0.75" }} />
                                            {" "}{!searchQuery && "Search Area"}
                                        </span>
                                    </div>
                                </div>

                                {filteredAreas.length > 0 ? (
                                    filteredAreas.map((area) => (
                                        <label
                                            key={area.area_code}
                                            className="form-check-label p-2 d-flex justify-content-between border-bottom text-wafaa-black-1"
                                        >
                                            <div>{area.area_name}</div>
                                            <input
                                                onClick={() => {
                                                    setGetArea(area.area_name);
                                                    setAreaCode(area.area_code);
                                                }}
                                                className="form-check-input"
                                                type="radio"
                                                name="area"
                                                value={area.area_name}
                                                checked={getArea === area.area_name}
                                            />
                                        </label>
                                    ))
                                ) : (
                                    <div className="text-center text-wafaa-black-1 mb-0">
                                        No areas or no city chosen
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer bg-wafaa-white-2">
                                <button className="w-100 btn btn-lg py-2-5 btn-primary bg-wafaa-purple-2 border-wafaa-purple shadow-wafaa-btn font-size-12">
                                    DONE
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AreaModal;
