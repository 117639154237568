import { Modal } from "bootstrap"; // Ensure Bootstrap's JS is imported
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import appIcon from "../../images/app_icon_1.png";
import AnimatedPage from "../FramerMotion/AnimatedPage";
import AuthOptions from "./AuthOptions";
import Carousel from "./Carousel";
import IosMessageDialog from "./IosMessageDialog";
import Splash from "./Splash";

function Auth({ baseURL, clientID, AppDisplayName, appSettings, emitEvent }) {
    const [isSplashVisible, setIsSplashVisible] = useState("d-flex");
    const [classLoginScreen, setClassLoginScreen] = useState("");
    const [isAuthOptionVisible, setIsAuthOptionVisible] = useState(false);
    const [isAuthOptionVisibleClass, setIsAuthOptionVisibleClass] =
        useState("d-none");
    const [authOptionPage, setAuthOptionPage] = useState("login");
    const [isIos, setIsIos] = useState(false);

    useEffect(() => {
        setTimeout(function () {
            setIsSplashVisible("opacity-0");
            setTimeout(function () {
                setIsSplashVisible("d-none");
            }, 1000);
        }, 3000);

        // Detect iOS on component mount
        detectMobileOS();
    }, []);

    function setAuthScreen(page) {
        if (page === "authoptionlogin") {
            setClassLoginScreen("opacity-0");
            setIsAuthOptionVisibleClass("opacity-0");
            setIsAuthOptionVisibleClass("opacity-100");

            setTimeout(function () {
                setIsAuthOptionVisible(true);
            }, 150);
        } else if (page === "authoptionjoin") {
            setClassLoginScreen("opacity-0");
            setIsAuthOptionVisibleClass("opacity-0");
            setAuthOptionPage("join");
            setIsAuthOptionVisibleClass("opacity-100");

            setTimeout(function () {
                setIsAuthOptionVisible(true);
            }, 150);
        } else if (page === "login-signup") {
            setIsAuthOptionVisibleClass("opacity-0");
            setClassLoginScreen("opacity-100");

            setTimeout(function () {
                setIsAuthOptionVisible(false);
            }, 150);
        }
    }

    const detectMobileOS = () => {
        const isIOSDevice = /iPad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream;
        setIsIos(isIOSDevice);
    };

    const handleInstallClick = () => {
        if (isIos) {
            // Programmatically trigger the modal on iOS
            const modalElement = document.getElementById("exampleModal");
            const iosModal = new Modal(modalElement); // Create a new Bootstrap modal instance
            iosModal.show(); // Show the modal programmatically
        } else {
            emitEvent(); // Handle non-iOS case
        }
    };

    return (
        <AnimatedPage className="h-100">
            <Splash isSplashVisible={isSplashVisible} appSettings={appSettings} />
            <div className="h-100 position-relative w-100 bg-white overflow-hidden">
                <div style={{ height: "65%" }} className="position-absolute top-0 w-100">
                    <Carousel baseURL={baseURL} clientID={clientID} AppDisplayName={AppDisplayName} appIcon={appIcon}/>
                </div>
                {isAuthOptionVisible === false ? (
                    <div
                        style={{
                            height: "35%",
                            // backgroundColor: "rgba(44, 54, 145, 0.09)",
                            backgroundColor: '#fcfdff',
                            overflowY: "scroll",
                        }}
                        className={
                            "position-absolute bottom-0 w-100 z-1 py-2 overflow-scroll transition-0-5-ease-in " +
                            classLoginScreen
                        }
                    >
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <div className="d-flex flex-column w-95 transition-0-5-ease">
                                <Link
                                    to="/login"
                                    className="btn btn-primary btn-lg py-2-5 bg-wafaa-purple border-wafaa-purple shadow-wafaa-btn font-size-12"
                                >
                                    Log in
                                </Link>
                                <Link
                                    to="/join"
                                    className="btn btn-light btn-lg py-2-5 shadow-wafaa-btn mt-3 font-size-12 text-wafaa-dark-blue"
                                >
                                    Join us
                                </Link>
                                <div
                                    onClick={handleInstallClick}
                                    className="btn btn-primary btn-lg py-2-5 shadow-wafaa-btn font-size-12  mt-2 "
                                >
                                    Install App
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className={
                            "h-20 transition-0-5-ease bg-white mt-n5 overflow-x-hidden position-absolute bottom-10 w-100 rounded-end-4 rounded-start-4 " +
                            isAuthOptionVisibleClass
                        }
                    >
                        <AuthOptions authOptionPage={authOptionPage} setAuthScreen={setAuthScreen} />
                    </div>
                )}
                {isIos && <IosMessageDialog />}
            </div>
        </AnimatedPage>
    );
}

export default Auth;
