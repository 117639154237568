import React, { useState } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import { useNavigate } from 'react-router-dom';
import Title from './misc/Title';
import Cookies from 'js-cookie';

import OtpInput from 'react-otp-input';
import Alert from './Alert/Alert';
import useAlert from "../../hooks/Alert";

function ForgotPasswordOTP({ baseURL, clientID }) {
    const {showAlert, setShowAlert, alertStyle, setAlertStyle, alertMessage, setAlertMessage, hideAlert} = useAlert();

    const sendPasswordResetEmail = async () => {
        const payLoad = {
            "DIV_ID": "1",
            "FUNCTION": "SendOTP",
            "SEND_KEY": "123456",
            "DATA": {
                "EMAIL_ID": userData,
                "CLIENT_ID": clientID
            }
        }

        const request = await fetch(`${baseURL}/SendOTP`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        })

        const response = await request.json();

        if (response.SUCCESS === "false") {
            hideAlert()
            setAlertStyle({
                background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
            })
            setAlertMessage({
                success: false,
                message: response.MESSAGE,
                mode: 'Forgot password'
            });
            setShowAlert('d-block');
        } else {
            // send email
            const sendEmailPayload = {
                "TemplateFile": "email/otpmail.json",
                "CLIENT_ID": clientID,
                "mailTo": [
                    {
                        "MailId": response.DATA.EMAIL_ID,
                        "MailName": "05533"
                    }
                ],
                "paramData": [
                    {
                        "OTP": response.DATA.OTP,
                        "MailName": ""
                    }
                ]

            }

            const sendEmailRequest = await fetch(`${baseURL}/LoyaltyEssOtpEmailSender`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sendEmailPayload)
            })

            if (sendEmailRequest.ok) {
                hideAlert()
                setAlertStyle({
                    background: 'linear-gradient(to right, #32bb71 0%, #2a9d8f 100%)'
                })
                setAlertMessage({
                    success: true,
                    message: "Email sent",
                    mode: 'Forgot password'
                });
                setShowAlert('d-block');
                Cookies.set('wafaa-user-data-reset-email', JSON.stringify(response.DATA.EMAIL_ID));
                setTimeout(() => {
                    navigate('/forgot-password-otp')
                }, 3500);
            } else {
                hideAlert()
                setAlertStyle({
                    background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                })
                setAlertMessage({
                    success: false,
                    message: "Failed to send email. Please retry",
                    mode: 'Forgot password'
                });
                setShowAlert('d-block');
            }
        }
    }

    const navigate = useNavigate();

    const [otp, setOtp] = useState('');

    let userData = {};

    try {
        userData = JSON.parse(Cookies.get('wafaa-user-data-reset-email'));
    } catch (error) {
        navigate('/');
    }

    const verifyOTP = async () => {
        if (otp === '') {
            hideAlert()
            setAlertStyle({
                background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
            })
            setAlertMessage({
                success: false,
                message: "Blank OTP. Please input OTP",
                mode: 'Verify OTP'
            });
            setShowAlert('d-block');
        } else {

            const payload = {
                "DIV_ID": "1",
                "FUNCTION": "VerifyOTP",
                "SEND_KEY": "123456",
                "DATA": {
                    "EMAIL_ID": userData,
                    "OTP": otp,
                    "CLIENT_ID": clientID
                }
            }

            const request = await fetch(`${baseURL}/VerifyOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })

            const response = await request.json();

            if (response.SUCCESS === 'false') {
                hideAlert()
                setAlertStyle({
                    background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                })
                setAlertMessage({
                    success: false,
                    message: response.MESSAGE,
                    mode: 'Verify OTP'
                });
                setShowAlert('d-block');
            } else {
                hideAlert()
                setAlertStyle({
                    background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                })
                setAlertMessage({
                    success: true,
                    message: response.MESSAGE,
                    mode: 'Verify OTP'
                });
                setShowAlert('d-block');
                navigate('/forgot-password-new-password');
            }
        }
    }

    return (
        <AnimatedPage>
            <Title />
            <Alert showAlert={showAlert} alertStyle={alertStyle} alertMessage={alertMessage} hideAlert={hideAlert}/>
            <div className='bg-white vh-100 d-flex flex-column justify-content-between px-4 py-5'>
                <div>
                    <p className='font-size-18 font-weight-bolder text-center mt-5'>OTP VERIFICATION</p>
                    <p className='mb-0 text-wafaa-grey font-size-12 text-center'>
                        Enter the OTP sent to <span className='font-weight-bolder text-dark'>- {userData}</span>
                    </p>
                    <div className='row m-0 p-0'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            inputStyle='bg-wafaa-grey border-0 rounded-2 mt-4 ps-2 py-3 shadow-wafaa-btn-inset form-control text-center mx-2'
                            skipDefaultStyles={true}
                            renderInput={(props) =>
                                <input className='bg-wafaa-grey border-0 rounded-2 mt-4 ps-2 py-3 shadow-wafaa-btn-inset form-control text-center mx-2' {...props} />
                            }
                        />
                    </div>
                    <p className='d-none text-center mt-4 text-wafaa-grey font-weight-bolder'>00:120 Sec</p>
                    <p className='text-center'>Didn't receive code? <span className='font-weight-bolder' onClick={async () => { await sendPasswordResetEmail() }}>Re-send</span></p>
                </div>
            </div>
            <div className='position-absolute d-flex flex-column justify-content-end p-4 w-100 bottom-0'>
                <button type='button' className='w-100 btn btn-lg py-2-5 btn-primary bg-wafaa-purple border-wafaa-purple shadow-wafaa-btn font-size-12' to='/forgot-password-new-password' onClick={() => {
                    verifyOTP()
                }}>
                    Submit
                </button>
            </div>
        </AnimatedPage>
    );
}

export default ForgotPasswordOTP;
