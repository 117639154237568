import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";
import useAlert from "../hooks/Alert";

const userThemeCookieName = "wafaa-user-data-theme";

const AppSettingContext = createContext();

export function useAppSetting() {
    return useContext(AppSettingContext);
}

const getSubdomain = (url) => {
    try {
        // Step 1: Extract the part after `://`
        let domain = url;
        if (url.includes("://")) {
            domain = url.split("://")[1];
        }

        // Step 2: Remove any port number (if present)
        domain = domain.split(":")[0];

        // Step 3: Check if the domain contains a '.' indicating a subdomain
        if (domain.includes(".")) {
            const domainParts = domain.split(".");
            return domainParts[0]; // Return the subdomain (first part before the first dot)
        } else {
            // If no '.' (e.g., localhost), fallback to "demo"
            return "demo";
        }
    } catch (error) {
        // Fallback to "demo" in case of errors or unusual URLs
        return "demo";
    }
};

export function AppSettingProvider(props) {
    const [appSettings, setAppSettings] = useState({});

    const [clientAccess, setClientAccess] = useState([]);

    const [theme, setTheme] = useState("light");

    const { setShowAlert, setAlertStyle, setAlertMessage, hideAlert } =
        useAlert();

    useEffect(() => {
        fetchBaseURL();
    }, []);

    const fetchBaseURL = async () => {
        const subdomainID = getSubdomain(new URL(window.location.href).origin);
        const payLoad = {
            FUNCTION: "FileReader",
            APP_ID: "TrueValue",
            VERSION: "V1.0",
            SUB_DOMAIN_ID: subdomainID,
            FileName: `LoyaltyApplication/settings/${subdomainID}/appconfig.json`,
        };
        const request = await fetch(
            "https://web2.mycomsys.com:8803/api/appconfig/v1/GetAppSettings",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payLoad),
            }
        );

        const response = await request.json();
        if (response.AppBaseURL) {
            setAppSettings(response);
            await fetchClientAccess(response);
        } else {
            if (
                subdomainID === "localhost:3000" ||
                subdomainID === "localhost:3002" ||
                subdomainID.includes("192")
            ) {
                fetchBaseURLRetryDefault();
            } else {
                hideAlert();
                setAlertStyle({
                    background:
                        "linear-gradient(to right, #f6743e 0%, #d42525 100%)",
                });
                setAlertMessage({
                    success: false,
                    message: "Failed to get app settings",
                    mode: "App Setting",
                });
                setShowAlert("d-block");
            }
        }

        setTheme(Cookies.get(userThemeCookieName) ?? "light");
    };

    const fetchClientAccess = async (settingsResponse) => {
        const payLoad = {
            DATA: {
                CLIENT_ID: settingsResponse.ClientId,
            },
        };

        const request = await fetch(settingsResponse.GetAccess, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payLoad),
        });

        const response = await request.json();

        setClientAccess(response?.DATA);
    };

    const fetchBaseURLRetryDefault = async () => {
        const subdomainID = getSubdomain(
            new URL("https://demo.elvisben.me.ke").origin
        );
        const payLoad = {
            FUNCTION: "FileReader",
            APP_ID: "TrueValue",
            VERSION: "V1.0",
            SUB_DOMAIN_ID: subdomainID,
            FileName: `LoyaltyApplication/settings/${subdomainID}/appconfig.json`,
        };
        const request = await fetch(
            "https://web2.mycomsys.com:8803/api/appconfig/v1/GetAppSettings",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payLoad),
            }
        );

        const response = await request.json();
        if (response.AppBaseURL) {
            setAppSettings(response);
            await fetchClientAccess(response);
        }
    };

    return (
        <AppSettingContext.Provider
            value={{ appSettings, clientAccess, theme, setTheme }}
        >
            {props.children}
        </AppSettingContext.Provider>
    );
}
