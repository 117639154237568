import { useEffect, useState } from 'react';

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  let days = Math.floor(countDown / (1000 * 60 * 60 * 24)).toString();
  if(days.split("").length < 2){
    days = `0${days}`;
  }

  let hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  ).toString();
  if(hours.split("").length < 2){
    hours = `0${hours}`;
  }

  let minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)).toString();
  if(minutes.split("").length < 2){
    minutes = `0${minutes}`;
  }

  let seconds = Math.floor((countDown % (1000 * 60)) / 1000).toString();
  if(seconds.split("").length < 2){
    seconds = `0${seconds}`;
  }

  return [days, hours, minutes, seconds];
};

export { useCountdown };