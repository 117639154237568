import React from "react";

function SecondOfferBoxSkeleton() {
    return (
        <div className="w-95 mx-auto shadow-wafaa-bg rounded-2 mb-3 bg-wafaa-white-2">
            <div className="row p-0 m-0 justify-content-between pt-3">
                <div className="col-7 ps-3">
                    <div className="d-flex flex-column justify-content-between h-100">
                        {/* Skeleton for text */}
                        <div
                            style={{
                                width: "80%",
                                height: "20px",
                                backgroundColor: "#E0E0E0",
                                marginBottom: "10px",
                                borderRadius: "4px",
                                animation: "shimmer 1.5s infinite",
                            }}
                        ></div>
                        <div
                            style={{
                                width: "60%",
                                height: "20px",
                                backgroundColor: "#E0E0E0",
                                borderRadius: "4px",
                                animation: "shimmer 1.5s infinite",
                            }}
                        ></div>
                    </div>
                </div>
                <div className="col-5 ps-0 ms-0">
                    {/* Skeleton for image */}
                    <div
                        style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#E0E0E0",
                            borderRadius: "4px",
                            animation: "shimmer 1.5s infinite",
                        }}
                    ></div>
                </div>
            </div>
            <div className="row p-2 m-0 justify-content-between pt-3">
                <div className="col-6 ps-3">
                    {/* Skeleton for dates */}
                    {/* <div
                        style={{
                            width: "70%",
                            height: "15px",
                            backgroundColor: "#E0E0E0",
                            marginBottom: "10px",
                            borderRadius: "4px",
                            animation: "shimmer 1.5s infinite",
                        }}
                    ></div> */}
                    <div
                        style={{
                            width: "50%",
                            height: "15px",
                            backgroundColor: "#E0E0E0",
                            borderRadius: "4px",
                            animation: "shimmer 1.5s infinite",
                        }}
                    ></div>
                </div>
            </div>

            {/* Inline CSS for shimmer effect */}
            <style>{`
                @keyframes shimmer {
                    0% {
                        background-position: -468px 0;
                    }
                    100% {
                        background-position: 468px 0;
                    }
                }

                div[style*="shimmer"] {
                    background: #f6f7f8;
                    background-image: linear-gradient(
                        to right,
                        #f6f7f8 0%,
                        #e0e0e0 20%,
                        #f6f7f8 40%,
                        #f6f7f8 100%
                    );
                    background-repeat: no-repeat;
                    background-size: 800px 104px;
                }
            `}</style>
        </div>
    );
}

export default SecondOfferBoxSkeleton;
