import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppSettingProvider } from "./context/appSettingContext";
import "./custom-style.css";
import reportWebVitals from "./reportWebVitals";
// import register from "./serviceWorkerRegistration"; // Correct default import
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppSettingProvider>
                <App />
            </AppSettingProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// Register the service worker
serviceWorkerRegistration.register();

let deferredPrompt;

// Handle the 'beforeinstallprompt' event
window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault(); // Prevent automatic prompt
    deferredPrompt = e; // Save the event for triggering later
    console.log(`'beforeinstallprompt' event was fired.`);

    // Optional: Automatically trigger the prompt without waiting for a user gesture
    // triggerInstallPrompt();  // Call function to trigger the prompt
});

// Custom event or manual trigger to show the PWA prompt
window.addEventListener("trueValuePWA", triggerInstallPrompt);

function triggerInstallPrompt() {
    if (deferredPrompt) {
        deferredPrompt.prompt(); // Show the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
                console.log("User accepted the A2HS prompt");
            } else {
                console.log("User dismissed the A2HS prompt");
            }
            deferredPrompt = null; // Clear the prompt variable after user action
        });
    } else {
        console.log("No deferredPrompt available at the moment.");
    }
}

// Log performance metrics
reportWebVitals();
