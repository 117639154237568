import React from "react";

const OfferBoxSkeleton = () => {
    return (
        <div
            // className="overflow-hidden"
            style={{
                width: "300px",
                minHeight: "400px",
                borderRadius: "10px",
                overflow: "hidden",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "16px",
                backgroundColor: "#f0f0f0", // Base color for the skeleton
                animation: "pulse 1.5s infinite", // Animation for skeleton
            }}
        >
            {/* Top section with image */}
            <div
                style={{
                    width: "100%",
                    height: "225px",
                    backgroundColor: "#ddd",
                    borderRadius: "8px",
                }}
            ></div>

            {/* Title placeholder */}
            <div
                style={{
                    width: "50%",
                    height: "20px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    margin: "16px 0 8px 0",
                }}
            ></div>

            {/* Description placeholder */}
            <div
                style={{
                    width: "100%",
                    height: "12px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    marginBottom: "8px",
                }}
            ></div>
            <div
                style={{
                    width: "80%",
                    height: "12px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    marginBottom: "8px",
                }}
            ></div>
            <div
                style={{
                    width: "60%",
                    height: "12px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    marginBottom: "16px",
                }}
            ></div>

            {/* Bottom section with placeholders for action buttons */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "#e0e0e0",
                        borderRadius: "50%",
                    }}
                ></div>
                <div
                    style={{
                        width: "60px",
                        height: "20px",
                        backgroundColor: "#e0e0e0",
                        borderRadius: "5px",
                    }}
                ></div>
                <div
                    style={{
                        width: "50px",
                        height: "20px",
                        backgroundColor: "#e0e0e0",
                        borderRadius: "5px",
                    }}
                ></div>
                <div
                    style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "#e0e0e0",
                        borderRadius: "5px",
                    }}
                ></div>
            </div>
        </div>
    );
};

// Inline keyframes for pulse animation
const style = document.createElement("style");
style.textContent = `
  @keyframes pulse {
    0% { background-color: #f0f0f0; }
    50% { background-color: #e0e0e0; }
    100% { background-color: #f0f0f0; }
  }
`;
document.head.appendChild(style);

export default OfferBoxSkeleton;
