import React from "react";

const SingleItemSkeleton = () => {
    const skeletonStyles = {
        container: {
            width: "95%", // You can adjust this based on your layout
            margin: "12px auto",
            padding: "5px",
            borderRadius: "8px",
            backgroundColor: "#f0f0f0",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            // flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: "center",
            animation: "pulse 1.5s infinite",
        },
        text: {
            width: "70%",
            height: "20px",
            backgroundColor: "#e0e0e0",
            borderRadius: "5px",
            margin: "8px 0",
        },
        icon: {
            width: "5%",
            height: "20px",

            backgroundColor: "#e0e0e0",
            borderRadius: "4px",
            // marginLeft: 'auto',
        },
    };

    return (
        <div style={skeletonStyles.container}>
            <div style={skeletonStyles.text}></div>
            <div style={skeletonStyles.icon}></div>
        </div>
    );
};

// Inline keyframes for pulse animation
const style = document.createElement("style");
style.textContent = `
  @keyframes pulse {
    0% { background-color: #f0f0f0; }
    50% { background-color: #e0e0e0; }
    100% { background-color: #f0f0f0; }
  }
`;
document.head.appendChild(style);

export default SingleItemSkeleton;
