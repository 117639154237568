import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import moment from 'moment';
import subtract from '../../images/icons/subtract.svg';
import JsBarcode from 'jsbarcode';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function VoucherSingle() {
    const location = useLocation();

    const navigate = useNavigate();

    const voucher = location.state;

    useEffect(() => {
        if (!voucher) {
            navigate('/vouchers');
        } else {
            JsBarcode('.js-bar-code', (voucher.Vouch_no));
        }
    }, [])

    return (
        <AnimatedPage className='overflow-hidden'>
            <div className='position-sticky z-2'>
                <PageTitle name='VOUCHER DETAILS' />
            </div>
            <div className='p-4 h-100 overflow-y-scroll pb-5 bg-wafaa-grey-4'>
                <div className='border border-3 border-bottom-0 border-dark border-end border-start-0 border-top-0 d-flex justify-content-between text-end pe-2'>
                    <img src={subtract} alt='Subtract' />
                    <div className='font-size-12 text-wafaa-black-1'>
                        <p className='mt-1 mb-0'>Voucher Value</p>
                        <p className='mt-1 mb-0 font-weight-700'>AED <span>{voucher.Redeem_value ?? ''}</span></p>
                    </div>
                </div>

                <div className='bg-white col-11 mt-4 mx-auto shadow text-center rounded-2 py-2 mb-3'>
                    <p className='font-size-12 mb-0 text-wafaa-grey'>Voucher Code</p>
                    <p className='font-size-25 text-wafaa-dark-blue font-weight-700 overflow-hidden'>
                        {voucher.Vouch_no ? voucher.Vouch_no : ''}
                    </p>
                </div>

                <p className='text-center font-size-12 text-wafaa-grey'>
                    Use this voucher you can pay in online store and in-stores
                </p>

                <hr />
                <img className='js-bar-code mx-auto d-block mb-2 border border-dark border-3 rounded-2' src='#' alt='Barcodes' />

                <p className='font-size-12 text-center text-success'>
                    Valid Until <span>{voucher.Valid_Until ? moment(voucher.Valid_Until).format('MMMM, DD YYYY') : ''}</span>
                </p>

                <div className='font-size-13 mt-4'>
                    <div className='text-wafaa-purple'>
                        Conditions apply
                    </div>

                    <div className='text-wafaa-black-1'>
                        1. This voucher is valid for a one-time use only and cannot be combined with any other promotions or discounts.
                    </div>
                    <div className='text-wafaa-black-1'>
                        2. The voucher must be presented at the time of checkout to be redeemed.
                    </div>
                    <div className='text-wafaa-black-1'>
                        3. The voucher is non-transferable and cannot be exchanged for cash or credit.
                    </div>
                    <div className='text-wafaa-black-1'>
                        4. Any unused portion of the voucher will be forfeited and cannot be redeemed at a later time.
                    </div>
                    <div className='text-wafaa-black-1'>
                        5. The voucher is valid until the expiry date stated on the voucher and cannot be extended beyond that date.
                    </div>

                </div>

                <Link to='/vouchers/single/transfer' state={voucher} type="button" className="bg-wafaa-purple btn text-white w-85 mb-2 mt-3 rounded-3 mx-auto d-block">
                    Share Voucher
                </Link>

                <button className='bg-white btn d-block mx-auto text-wafaa-purple w-85 mb-3' onClick={() => navigate(-1)}>
                    Cancel
                </button>
            </div>
        </AnimatedPage>
    );
}

export default VoucherSingle;
