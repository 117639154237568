import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import ice_cream_logo from '../../images/ice_cream_logo.png';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useAuth } from '../../context/userContext';

function SingleTransactionRedemption({ baseURL, clientID, AppDisplayName }) {

    const { authUser } = useAuth();

    const { billref } = useParams();

    const [transactionHistory, setTransactionHistory] = useState({});

    const [voucherIssued, setVoucherIssued] = useState('No');

    const [voucherRedeemed, setVoucherRedeemed] = useState({});

    useEffect(() => {
        if (authUser) {
            getCustomerHistoryData(baseURL);
        }
    }, [authUser]);

    const getCustomerHistoryData = async (baseURL) => {
        const payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "GetLoyPoints",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "TYPE_NAME": "CUSTOMER_HISTORY",
                "MOBILE_NUM": authUser.MOBILE ?? '',
                "FROM_DATE": "2023-01-01",
                "TO_DATE": "2024-01-30",
                "CLIENT_ID": clientID
            }
        }
        const request = await fetch(`${baseURL}/GetLoyPoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        if (request.ok) {
            const bill = response.BILL_DETAILS.filter((item) => {
                return item.BILLREF === billref;
            });
            const item = response.ITEM_DETAILS.filter((item) => {
                return item.BILLREF === billref;
            });

            const voucherIssued = response.VOUCHER_ISSUED.filter((item) => {
                return item.BILLREF === billref;
            });

            const voucherRedeemed = response.VOUCHER_REDEEMED.filter((item) => {
                return item.BILLREF === billref;
            });

            const pointsEarned = response.POINTS_EARNED.filter((item) => {
                return item.BILLREF === billref;
            });

            setTransactionHistory({
                BILL_DETAILS: bill,
                ITEM_DETAILS: item,
                VOUCHER_ISSUED: voucherIssued,
                VOUCHER_REDEEMED: voucherRedeemed,
                POINTS_EARNED: pointsEarned
            });

            const issued = response.VOUCHER_ISSUED.filter((item) => {
                return item.BILLREF === billref;
            });

            if (issued.length > 0) {
                setVoucherIssued('Yes')
            }

            const redeemed = response.VOUCHER_REDEEMED.filter((item) => {
                return item.BILLREF === billref;
            });

            if (redeemed) {
                setVoucherRedeemed(redeemed)
            }
        } else {
            setTransactionHistory([]);
        }
    }

    return (
        <AnimatedPage>
            <div className='position-sticky z-2'>
                <PageTitle name='TRANSACTION DETAILS' />
            </div>
            <div className='w-100 h-auto overflow-y-scroll pb-5 bg-wafaa-white-3'>

                <div className='w-90 mx-auto pb-5 mt-3'>
                    <div className='height-70 width-70 rounded-4 overflow-hidden d-none'>
                        <img src={ice_cream_logo} alt='Ice cream logo' className='w-100 h-100' />
                    </div>
                    <table className='table table-borderless pb-5 table-fixed mt-4'>
                        <tbody>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">{AppDisplayName ? AppDisplayName : "True Value"} ID Scan</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{transactionHistory && transactionHistory.ITEM_DETAILS ? moment(transactionHistory.TRAN_DATE).format('DD MM YYYY, h:m:s a') : ''}</td>
                            </tr>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">Redeem Date</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{voucherRedeemed && voucherRedeemed.length ? moment(voucherRedeemed[0].REEDEM_DATE).format('DD MM YYYY, h:m:s a') : ''}</td>
                            </tr>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">Value</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{voucherRedeemed && voucherRedeemed.length ? voucherRedeemed[0].VALUE : ''}</td>
                            </tr>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">Total Paid</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{transactionHistory && transactionHistory.BILL_DETAILS ? transactionHistory.BILL_DETAILS[0].BILL_AMOUNT : 0} AED</td>
                            </tr>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">Bill Reference</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{billref}</td>
                            </tr>
                            <tr className="border border-top-0 border-end-0 border-start-0 border-wafaa-grey-opacity-50 border-1">
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-2 py-3">{AppDisplayName ? AppDisplayName : "TRUE VALUE"} ID</td>
                                <td className="font-size-13 text-wafaa-dark-blue-2 bg-transparent px-0 py-3">{transactionHistory && transactionHistory.POINTS_EARNED ? transactionHistory.POINTS_EARNED[0].CUSTOMER_CODE : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='position-absolute bottom-15 text-center w-100 d-none'>
                    <Link to='/digital-receipt' className='btn col-11 font-size-14 bg-wafaa-purple-2 text-white rounded-4 py-3'>
                        Generate Digital Receipt
                    </Link>
                </div>
            </div>

        </AnimatedPage>
    )
}
export default SingleTransactionRedemption;