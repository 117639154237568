import React from "react";
import { useNavigate } from "react-router-dom";
import bg_circles from "../../images/bg-circle.svg";
import back_arrow from "../../images/icons/material-symbols_arrow-back-ios-new.svg";

function PageTitle({ name, children, hideBackButton }) {
    let navigate = useNavigate();

    return (
        <div
            className="w-100 bg-wafaa-purple-2 py-3"
            style={{
                backgroundImage: `url(${bg_circles})`,
            }}
        >
            {hideBackButton === true ? (
                ""
            ) : (
                <div className="d-flex align-items-center text-white font-size-16 text-uppercase">
                    <span className="d-block me-2" onClick={() => navigate(-1)}>
                        <img src={back_arrow} alt="Back button iOS" />
                    </span>
                    {name}
                </div>
            )}
            {children}
        </div>
    );
}

export default PageTitle;
