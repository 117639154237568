import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/userContext";
import useAlert from "../../hooks/Alert";
import calendar_icon from "../../images/icons/bi_calendar-date.svg";
import AnimatedPage from "../FramerMotion/AnimatedPage";
import Alert from "./Alert/Alert";
import Title from "./misc/Title";
import NativeDropdown from "../Common/NativeDropdown"

function SignUp({ baseURL, clientID, appSettings, AppDisplayName }) {
    const { CountryResidence, CountryResidenceCode, Nationality } = appSettings;
    const [countries, setCountries] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [nationalityFiltered, setNationalityFiltered] = useState([]);

    const [countriesFiltered, setCountriesFiltered] = useState([]);

    const [residenceCountry, setResidenceCountry] = useState(
        CountryResidence ? CountryResidence : ""
    );

    const [countryCode, setCountryCode] = useState(
        CountryResidenceCode ? CountryResidenceCode : ""
    );

    const [residenceFilter, setResidenceFilter] = useState("");
    const [cityFilterValue, setCityFilterValue] = useState("");
    const [areaFilterValue, setAreaFilterValue] = useState("");

    const [residenceDisplay, setResidenceDisplay] =
        useState("height-0 opacity-0");

    const residenceUL = useRef();

    const [nationality, setNationality] = useState(
        Nationality ? Nationality : ""
    );

    const [nationalityFilter, setNationalityFilter] = useState("");

    const [nationalityDisplay, setNationalityDisplay] =
        useState("height-0 opacity-0");

    const nationalityUL = useRef();

    const [gender, setGender] = useState("MALE");

    const [genderDisplay, setGenderDisplay] = useState("height-0 opacity-0");

    const genderUL = useRef();

    const [city, setCity] = useState("");

    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const [cityDisplay, setCityDisplay] = useState("height-0 opacity-0");

    const [cityCode, setCityCode] = useState("");

    const cityUL = useRef();

    const [area, setArea] = useState("");

    const [areas, setAreas] = useState([]);
    const [filteredAreas, setFilteredAreas] = useState([]);

    const [areaDisplay, setAreaDisplay] = useState("height-0 opacity-0");

    const [areaCode, setAreaCode] = useState("");

    const areaUL = useRef();

    const navigate = useNavigate();

    const { setAuthUser } = useAuth();

    const {
        showAlert,
        setShowAlert,
        alertStyle,
        setAlertStyle,
        alertMessage,
        setAlertMessage,
        hideAlert,
    } = useAlert();

    const dateInput = useRef();
    const dateMaskInput = useRef();
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    function setInputHeight(event) {
        // If input is focused, add the mt-4 class
        if (event.type === "focus") {
            event.target.classList.add("mt-4");
        }

        // If input is blurred, conditionally remove the mt-4 class
        if (event.type === "blur") {
            setTimeout(() => {
                const inputValue = event.target.value.trim();
                // Remove mt-4 only if no filter is open and input is empty
                if (inputValue === "" && !isFilterOpen) {
                    event.target.classList.remove("mt-4");
                }
            }, 800);
        }

        // Always add mt-4 if the input has a value
        if (event.target.value.trim() !== "") {
            event.target.classList.add("mt-4");
        }
    }

    function openDate() {
        dateInput.current.showPicker();
        dateInput.current.focus();
    }

    function updateInputMask(event) {
        if (event.target.value === "") {
            dateMaskInput.current.classList.remove("mt-4");
        } else {
            dateMaskInput.current.value = event.target.value;
            dateMaskInput.current.classList.add("mt-4");
        }
    }

    async function sendSignUp(event, baseURL, closure) {
        const formData = new FormData(event.target.form);
        const validateForm = () => {
            const firstName = formData.get("first_name");
            const lastName = formData.get("last_name");
            const email = formData.get("email");
            const phoneNumber = formData.get("phone_number");
            const dateOfBirth = formData.get("date_of_birth");
            const gender = formData.get("gender");
            const nationality = formData.get("nationality");
            const country = formData.get("country");
            const city = formData.get("city");
            const area = formData.get("area");
            const password = formData.get("password");
            const confirmPassword = formData.get("confirm_password");

            // Example validations
            if (
                !firstName ||
                !lastName ||
                !email ||
                !phoneNumber ||
                !dateOfBirth ||
                !gender ||
                !nationality ||
                !country ||
                !city ||
                !area ||
                !password ||
                !confirmPassword
            ) {
                return { valid: false, message: "All fields are required." };
            }

            // Example: Check if email is valid
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(email)) {
                return {
                    valid: false,
                    message: "Please enter a valid email address.",
                };
            }

            // Example: Check if phone number is valid
            const phonePattern = /^[0-9]{5,15}$/; // Adjust pattern according to your requirements
            if (!phonePattern.test(phoneNumber)) {
                return {
                    valid: false,
                    message: "Please enter a valid phone number.",
                };
            }

            // Check if dateOfBirth is at least 18 years ago
            const dob = new Date(dateOfBirth);
            const today = new Date();
            const age = today.getFullYear() - dob.getFullYear();
            const monthDiff = today.getMonth() - dob.getMonth();
            const isBirthdayPassed =
                monthDiff > 0 ||
                (monthDiff === 0 && today.getDate() >= dob.getDate());

            if (age < 18 || (age === 18 && !isBirthdayPassed)) {
                return {
                    valid: false,
                    message: "You must be at least 18 years old.",
                };
            }

            // Check if password is greater than 3 characters
            if (password.length <= 3) {
                return {
                    valid: false,
                    message: "Password must be greater than 3 characters.",
                };
            }
            if (confirmPassword.length <= 3) {
                return {
                    valid: false,
                    message:
                        "Confirm Password must be greater than 3 characters.",
                };
            }

            if (password.length > 3 && confirmPassword.length > 3) {
                if (password !== confirmPassword) {
                    return {
                        valid: false,
                        message: "Password and confirm password should match",
                    };
                }
            }

            // Add any other validations you need here...

            return { valid: true, message: "" };
        };

        // Validate the form
        const validationResponse = validateForm();
        if (!validationResponse.valid) {
            hideAlert();
            setAlertStyle({
                background:
                    "linear-gradient(to right, #f6743e 0%, #d42525 100%)",
            });
            setAlertMessage({
                success: false,
                message: validationResponse.message,
                mode: "Registration",
            });
            setShowAlert("d-block");
            return; // Stop execution if validation fails
        }
        const capitalizeFirstLetter = (string) => {
            if (!string) return ""; // Handle empty string
            return (
                string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
            );
        };

        if (validationResponse.valid) {
            const payLoad = {
                DIV_DES: "",
                DIV_ID: "1",
                FUNCTION: "LoyCreateCustomer",
                SEND_KEY: "123456",
                DATA: {
                    AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                    TYPE_NAME: "CUSTOMER",
                    CLIENT_ID: clientID,
                    CUSTOMER_DETAILS: {
                        FIRSTNAME: capitalizeFirstLetter(
                            formData.get("first_name")
                        ),
                        LASTNAME: capitalizeFirstLetter(
                            formData.get("last_name")
                        ),
                        MOBILE: formData.get("phone_number"),
                        EMAIL: formData.get("email"),
                        ADDR1: "addr1",
                        ADDR2: "addr1",
                        ADDR3: "addr3",
                        BIRTHDATE: formData.get("date_of_birth"),
                        VALID: "1",
                        LABOURCARDNO: "",
                        LOCID: "",
                        NATIONALITY: formData.get("nationality"),
                        COUNTRY: formData.get("country"),
                        GENDER: formData.get("gender"),
                        CITY: formData.get("city"),
                        AREA: formData.get("area"),
                        country_code: countryCode,
                        city_code: cityCode,
                        area_code: areaCode,
                    },
                    LOGIN_DETAILS: {
                        USERNAME: formData.get("email"),
                        PWORD: formData.get("password"),
                        VALID: "Y",
                    },
                },
            };

            const request = await fetch(`${baseURL}/LoyCreateCustomer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payLoad),
            });

            try {
                const response = await request.json();
                if (response?.STATUS) {
                    hideAlert();
                    setAlertStyle({
                        background:
                            "linear-gradient(to right, #32bb71 0%, #2a9d8f 100%)",
                    });
                    setAlertMessage({
                        success: true,
                        message: response?.MESSAGE || "Sign Up Successfull",
                        mode: "Sign Up",
                    });
                    setShowAlert("d-block");
                    setTimeout(() => {
                        closure("/login");
                    }, 2000);
                } else {
                    hideAlert();

                    setAlertStyle({
                        background:
                            "linear-gradient(to right, #f6743e 0%, #d42525 100%)",
                    });

                    setAlertMessage({
                        success: false,
                        message: response?.MESSAGE || "Failed to register",
                        mode: "Registration",
                    });
                    setShowAlert("d-block");
                    throw new Error("Invalid");
                }
            } catch (error) {
                hideAlert();
                setAlertStyle({
                    background:
                        "linear-gradient(to right, #f6743e 0%, #d42525 100%)",
                });
                setAlertMessage({
                    success: false,
                    message: "Failed to register",
                    mode: "Registration",
                });
                setShowAlert("d-block");
            }
        } else {
            hideAlert();
            setAlertStyle({
                background:
                    "linear-gradient(to right, #f6743e 0%, #d42525 100%)",
            });
            setAlertMessage({
                success: false,
                message: "Failed to register. Inputs not valid",
                mode: "Registration",
            });
            setShowAlert("d-block");
        }
    }

    const handleResidenceFilter = (event) => {
        const filteredCountries = countries.filter((country) =>
            country.country_name
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
        );

        setCountriesFiltered(filteredCountries);
        setResidenceFilter(event.target.value);
    };
    const handleCityFilter = (event) => {
        const filteredCities = cities.filter((city) =>
            city.city_name
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
        );

        setFilteredCities(filteredCities);
        setCityFilterValue(event.target.value);
    };
    const handleAreaFilter = (event) => {
        const filteredAreas = areas.filter((area) =>
            area.area_name
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
        );

        setFilteredAreas(filteredAreas);
        setAreaFilterValue(event.target.value);
    };

    const handleResidenceSelect = () => {
        const listElements = residenceUL.current.querySelectorAll("li");

        const elementTop = residenceUL.current?.getBoundingClientRect().top;

        let element,
            top,
            lowerMin = -100,
            lowerMax = -80,
            upperMin = 25,
            upperMax = 30;

        for (let i = 0; i < listElements.length; i++) {
            top = Math.abs(listElements[i]?.getBoundingClientRect().top);
            const difference = top - elementTop;

            if (difference < 0) {
                if (difference >= lowerMin && difference <= lowerMax) {
                    element = listElements[i];
                }
            } else {
                if (
                    top - elementTop >= upperMin &&
                    top - elementTop <= upperMax
                ) {
                    element = listElements[i];
                }
            }
        }
        setResidenceCountry(element?.getAttribute("value"));
        setCountryCode(element?.getAttribute("code"));
        setCountriesFiltered(countries);
        setResidenceFilter("");
        closeFilter("residence");
    };

    const handleNationalityChange = (event) => {
        const filteredCountries = nationalities.filter((country) =>
            country.country_name
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
        );
        setNationalityFiltered(filteredCountries);
        setNationalityFilter(event.target.value);
    };

    const handleNationalitySelect = () => {
        const listElements = nationalityUL.current.querySelectorAll("li");

        const elementTop = nationalityUL.current?.getBoundingClientRect().top;

        let element,
            top,
            lowerMin = -100,
            lowerMax = -80,
            upperMin = 25,
            upperMax = 30;

        for (let i = 0; i < listElements.length; i++) {
            top = Math.abs(listElements[i]?.getBoundingClientRect().top);
            const difference = top - elementTop;

            if (difference < 0) {
                if (difference >= lowerMin && difference <= lowerMax) {
                    element = listElements[i];
                }
            } else {
                if (
                    top - elementTop >= upperMin &&
                    top - elementTop <= upperMax
                ) {
                    element = listElements[i];
                }
            }
        }

        setNationality(element.getAttribute("value"));
        setNationalityFiltered(nationalities);
        setNationalityFilter("");
        closeFilter("nationality");
    };

    const handleGenderSelect = () => {
        const listElements = genderUL.current.querySelectorAll("li");

        const elementTop = genderUL.current?.getBoundingClientRect().top;

        let element,
            top,
            lowerMin = -100,
            lowerMax = -80,
            upperMin = 25,
            upperMax = 30;

        for (let i = 0; i < listElements.length; i++) {
            top = Math.abs(listElements[i]?.getBoundingClientRect().top);
            const difference = top - elementTop;

            if (difference < 0) {
                if (difference >= lowerMin && difference <= lowerMax) {
                    element = listElements[i];
                }
            } else {
                if (
                    top - elementTop >= upperMin &&
                    top - elementTop <= upperMax
                ) {
                    element = listElements[i];
                }
            }
        }

        setGender(element.getAttribute("value"));
        closeFilter("gender");
    };

    const handleCitySelect = () => {
        const listElements = cityUL.current.querySelectorAll("li");

        const elementTop = cityUL.current?.getBoundingClientRect().top;

        let element,
            top,
            lowerMin = -100,
            lowerMax = -80,
            upperMin = 25,
            upperMax = 30;

        for (let i = 0; i < listElements.length; i++) {
            top = Math.abs(listElements[i]?.getBoundingClientRect().top);
            const difference = top - elementTop;

            if (difference < 0) {
                if (difference >= lowerMin && difference <= lowerMax) {
                    element = listElements[i];
                }
            } else {
                if (
                    top - elementTop >= upperMin &&
                    top - elementTop <= upperMax
                ) {
                    element = listElements[i];
                }
            }
        }

        setCity(element?.getAttribute("value"));
        setCityCode(element?.getAttribute("code"));
        closeFilter("city");
    };

    const handleAreaSelect = () => {
        const listElements = areaUL.current.querySelectorAll("li");

        const elementTop = areaUL.current?.getBoundingClientRect().top;

        let element,
            top,
            lowerMin = -100,
            lowerMax = -80,
            upperMin = 25,
            upperMax = 30;

        for (let i = 0; i < listElements.length; i++) {
            top = Math.abs(listElements[i]?.getBoundingClientRect().top);
            const difference = top - elementTop;

            if (difference < 0) {
                if (difference >= lowerMin && difference <= lowerMax) {
                    element = listElements[i];
                }
            } else {
                if (
                    top - elementTop >= upperMin &&
                    top - elementTop <= upperMax
                ) {
                    element = listElements[i];
                }
            }
        }

        setArea(element?.getAttribute("value"));
        setAreaCode(element?.getAttribute("code"));
        closeFilter("area");
    };

    const openFilter = (type) => {
        closeFilterRest();

        if (type === "residence") {
            setResidenceDisplay("height-250 opacity-100");
        } else if (type === "nationality") {
            setNationalityDisplay("height-250 opacity-100");
        } else if (type === "gender") {
            setGenderDisplay("height-250 opacity-100");
        } else if (type === "city") {
            setCityDisplay("h-100 opacity-100");
        } else if (type === "area") {
            setAreaDisplay("height-250 opacity-100");
        }
    };

    const closeFilterRest = () => {
        // Reset all filter displays to their closed state
        setResidenceDisplay("height-0 opacity-0"); // Adjust as necessary
        setNationalityDisplay("height-0 opacity-0");
        setGenderDisplay("height-0 opacity-0");
        setCityDisplay("height-0 opacity-0");
        setAreaDisplay("height-0 opacity-0");
    };

    const closeFilter = (type) => {
        setIsFilterOpen(false);
        if (type === "residence") {
            setResidenceDisplay("height-0 opacity-0");
        }

        if (type === "nationality") {
            setNationalityDisplay("height-0 opacity-0");
        }

        if (type === "gender") {
            setGenderDisplay("height-0 opacity-0");
        }

        if (type === "city") {
            setCityDisplay("height-0 opacity-0");
        }

        if (type === "area") {
            setAreaDisplay("height-0 opacity-0");
        }
    };

    useEffect(() => {
        getCountries();
        getNationalities();
    }, []);

    async function getNationalities() {
        const payLoad = {
            DATA: {
                client_id: appSettings.ClientId,
                data_type: "M_COUNTRY",
                flag: "ALL",
            },
        };

        try {
            const request = await fetch(
                `${appSettings.AppBaseURL1}CRM_DownloadCou_Ci_Ar`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payLoad),
                }
            );

            const response = await request.json();

            setNationalities(response.DATA);

            setNationalityFiltered(response.DATA);
        } catch (error) {
            console.error(error);
            hideAlert();
            setAlertStyle({
                background:
                    "linear-gradient(to right, #f6743e 0%, #d42525 100%)",
            });
            setAlertMessage({
                success: false,
                message: "Cannot load countries",
                mode: "Countries",
            });
            setShowAlert("d-block");
        }
    }

    async function getCountries() {
        const payLoad = {
            DATA: {
                client_id: appSettings.ClientId,
                data_type: "M_COUNTRY",
                flag: "Y",
            },
        };

        try {
            const request = await fetch(
                `${appSettings.AppBaseURL1}CRM_DownloadCou_Ci_Ar`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payLoad),
                }
            );

            const response = await request.json();

            setCountries(response.DATA);

            setCountriesFiltered(response.DATA);
        } catch (error) {
            console.error(error);
            setAlertStyle({
                background:
                    "linear-gradient(to right, #f6743e 0%, #d42525 100%)",
            });
            setAlertMessage({
                success: false,
                message: "Cannot load countries",
                mode: "Countries",
            });
            setShowAlert("d-block");
        }
    }

    useEffect(() => {
        getCities();
    }, [residenceCountry, CountryResidence]);

    async function getCities() {
        const payLoad = {
            DATA: {
                client_id: appSettings.ClientId,
                data_type: "M_CITY",
                country_code: countryCode || CountryResidenceCode,
            },
        };

        try {
            const request = await fetch(
                `${appSettings.AppBaseURL1}CRM_DownloadCou_Ci_Ar`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payLoad),
                }
            );

            const response = await request.json();

            setCities(response.DATA);
            setFilteredCities(response.DATA);
        } catch (error) {
            console.error(error);
            setAlertStyle({
                background:
                    "linear-gradient(to right, #f6743e 0%, #d42525 100%)",
            });
            setAlertMessage({
                success: false,
                message: "Cannot load cities",
                mode: "Cities",
            });
            setShowAlert("d-block");
        }
    }

    useEffect(() => {
        getAreas();
    }, [cityCode]);

    async function getAreas() {
        const payLoad = {
            DATA: {
                client_id: appSettings.ClientId,
                data_type: "M_AREA",
                country_code: countryCode,
                city_code: cityCode,
            },
        };

        try {
            const request = await fetch(
                `${appSettings.AppBaseURL1}CRM_DownloadCou_Ci_Ar`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payLoad),
                }
            );

            const response = await request.json();

            setAreas(response.DATA);
            setFilteredAreas(response.DATA);
        } catch (error) {
            console.error(error);
            setAlertStyle({
                background:
                    "linear-gradient(to right, #f6743e 0%, #d42525 100%)",
            });
            setAlertMessage({
                success: false,
                message: "Cannot load areas",
                mode: "Areas",
            });
            setShowAlert("d-block");
        }
    }

    return (
        <AnimatedPage>
            <Title name="SIGN UP" />
            <Alert
                showAlert={showAlert}
                alertStyle={alertStyle}
                alertMessage={alertMessage}
                hideAlert={hideAlert}
            />
            <div className="h-90">
                <form className="bg-white h-100" autoComplete="off">
                    <div className="w-85 mx-auto bg-white pb-5 h-95">
                        <div className="font-size-12 h-100 overflow-y-scroll pt-4">
                            {/* <div className='form-group position-relative'>
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>Title*</label>
                                <input className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => setInputHeight(event)} onBlur={(event) => setInputHeight(event)} type='text' name='title' />
                            </div> */}
                            <div className="form-group position-relative mt-4">
                                <label className="text-wafaa-grey position-absolute bg-white z-0">
                                    First Name
                                </label>
                                <input
                                    className="font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1"
                                    onFocus={(event) => setInputHeight(event)}
                                    onBlur={(event) => setInputHeight(event)}
                                    type="text"
                                    name="first_name"
                                />
                            </div>
                            <div className="form-group position-relative mt-4">
                                <label className="text-wafaa-grey position-absolute bg-white z-0">
                                    Last Name
                                </label>
                                <input
                                    className="font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1"
                                    onFocus={(event) => setInputHeight(event)}
                                    onBlur={(event) => setInputHeight(event)}
                                    type="text"
                                    name="last_name"
                                />
                            </div>
                            <div className="form-group position-relative mt-4">
                                <img
                                    src={calendar_icon}
                                    alt="Calendar icon"
                                    className="position-absolute right-0"
                                />
                                <label className="text-wafaa-grey position-absolute bg-white z-0">
                                    Date of birth
                                </label>
                                <input
                                    readOnly
                                    ref={dateMaskInput}
                                    className="font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1"
                                    onClick={(event) => {
                                        setInputHeight(event, true);
                                        openDate();
                                    }}
                                    onBlur={(event) => setInputHeight(event)}
                                    type="text"
                                />
                                <input
                                    className="height-0 left-0 opacity-0 position-absolute z-n1"
                                    ref={dateInput}
                                    onChange={(event) => updateInputMask(event)}
                                    type="date"
                                    name="date_of_birth"
                                />
                            </div>
                            <p className="my-4 font-size-12 text-wafaa-grey d-none">
                                To join{" "}
                                {AppDisplayName ? AppDisplayName : "True Value"}{" "}
                                as an individual,you should be of legal age
                                defined by your country of residence. Please
                                refer to Terms & Conditions.
                            </p>
                            <div className="form-group position-relative mt-4">
                                <label className="text-wafaa-grey position-absolute bg-white z-0">
                                    Phone Number
                                </label>
                                <input
                                    className="font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1"
                                    onFocus={(event) => setInputHeight(event)}
                                    onBlur={(event) => setInputHeight(event)}
                                    type="number"
                                    name="phone_number"
                                    maxLength="10"
                                />
                            </div>
                            <div className="form-group position-relative mt-4">
                                <label className="text-wafaa-grey position-absolute bg-white z-0">
                                    Gender
                                </label>
                                <input
                                    value={gender}
                                    readOnly
                                    name="gender"
                                    required
                                    className="mt-4 outline-none border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1"
                                    onFocus={(event) => {
                                        setInputHeight(event);
                                        openFilter("gender");
                                    }}
                                    onBlur={(event) => setInputHeight(event)}
                                />
                            </div>
                            <div className="form-group position-relative mt-4">
                                <label className="text-wafaa-grey position-absolute bg-white z-0">
                                    Country
                                </label>
                                <input
                                    value={residenceCountry}
                                    readOnly
                                    name="country"
                                    required
                                    className={`outline-none ${
                                        residenceCountry ? "mt-4" : ""
                                    } border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1`}
                                    // onFocus={(event) => {
                                    //     setInputHeight(event);
                                    //     openFilter("residence");
                                    // }}
                                    // onBlur={(event) => setInputHeight(event)}
                                />
                                <input
                                    type="hidden"
                                    value={countryCode}
                                    name="country_code"
                                />
                            </div>
                            <div className="form-group position-relative mt-4">
                                <label className="text-wafaa-grey position-absolute bg-white z-0">
                                    City
                                </label>
                                <input
                                    value={city}
                                    readOnly
                                    name="city"
                                    required
                                    className={`outline-none  ${
                                        city ? "mt-4" : ""
                                    } border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-11`}
                                    onFocus={(event) => {
                                        setInputHeight(event);
                                        openFilter("city");
                                    }}
                                    onBlur={(event) => setInputHeight(event)}
                                />
                                <input
                                    type="hidden"
                                    value={cityCode}
                                    name="city_code"
                                />
                            </div>
                            <div className="form-group position-relative mt-4">
                                <label className="text-wafaa-grey position-absolute bg-white z-0">
                                    Area
                                </label>
                                <input
                                    value={area}
                                    readOnly
                                    name="area"
                                    required
                                    className={`outline-none ${
                                        area ? "mt-4" : ""
                                    }  border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1`}
                                    onFocus={(event) => {
                                        setInputHeight(event);
                                        openFilter("area");
                                    }}
                                    onBlur={(event) => setInputHeight(event)}
                                />
                                <input
                                    type="hidden"
                                    value={areaCode}
                                    name="area_code"
                                />
                            </div>
                            <div className="form-group position-relative mt-4 mb-3">
                                <label className="text-wafaa-grey position-absolute bg-white z-0">
                                    Nationality
                                </label>
                                <input
                                    value={nationality}
                                    name="nationality"
                                    readOnly
                                    required
                                    className={`outline-none  ${
                                        nationality ? "mt-4" : ""
                                    } border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1`}
                                    onFocus={(event) => {
                                        setInputHeight(event);
                                        openFilter("nationality");
                                    }}
                                    onBlur={(event) => setInputHeight(event)}
                                />
                            </div>

                            <div className="pb-2">
                                <div className="form-group position-relative mt-2">
                                    <label className="text-wafaa-grey position-absolute bg-white z-0">
                                        Email address
                                    </label>
                                    <input
                                        className="font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1"
                                        onFocus={(event) =>
                                            setInputHeight(event)
                                        }
                                        onBlur={(event) =>
                                            setInputHeight(event)
                                        }
                                        type="email"
                                        name="email"
                                    />
                                </div>
                                <div className="form-group position-relative mt-3">
                                    <label className="text-wafaa-grey position-absolute bg-white z-0">
                                        Password*
                                    </label>
                                    <input
                                        className="font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1"
                                        onFocus={(event) =>
                                            setInputHeight(event)
                                        }
                                        onBlur={(event) =>
                                            setInputHeight(event)
                                        }
                                        type="password"
                                        name="password"
                                    />
                                </div>
                                <div className="form-group position-relative mt-3">
                                    <label className="text-wafaa-grey position-absolute bg-white z-0">
                                        Confirm Password*
                                    </label>
                                    <input
                                        className="font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1"
                                        onFocus={(event) =>
                                            setInputHeight(event)
                                        }
                                        onBlur={(event) =>
                                            setInputHeight(event)
                                        }
                                        type="password"
                                        name="confirm_password"
                                    />
                                </div>
                            </div>

                            <p className="font-size-15 font-weight-bolder text-wafaa-grey my-4 d-none">
                                Communication Preferences
                            </p>
                            <p className="my-4 text-wafaa-grey d-none">
                                We want to send you the latest offers and
                                news.Let us know how you'd like to stay in
                                touch.
                            </p>

                            <div className="d-none justify-content-between align-items-center text-wafaa-grey my-4">
                                <p>SMS</p>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input font-size-20"
                                        type="checkbox"
                                    />
                                </div>
                            </div>

                            <div className="d-none justify-content-between align-items-center text-wafaa-grey my-4">
                                <p>E-mail</p>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input font-size-20"
                                        type="checkbox"
                                    />
                                </div>
                            </div>

                            <p className="text-wafaa-grey d-none">
                                *Service messages will be sent via one of the
                                above channels to ensure you are kept up to date
                            </p>
                        </div>
                    </div>
                    <div className="text-center position-fixed bottom-10 bg-white w-100 height-50 pt-2 max-width-700">
                        <button
                            onClick={(e) => sendSignUp(e, baseURL, navigate)}
                            type="button"
                            className="w-85 btn btn-lg py-2-5 btn-primary bg-wafaa-purple border-wafaa-purple shadow-wafaa-btn font-size-12"
                        >
                            Sign up
                        </button>
                    </div>
   
                </form>
            </div>

            <div
                className={
                    "max-width-700 bg-white w-100 bottom-0 position-fixed z-2 transition-0-2-ease " +
                    residenceDisplay
                }
                style={{ boxShadow: "0px 0px 10px 1000px rgba(0,0,0, 0.6)" }}
            >
                <div className="h-100 w-100 position-relative bg-transparent">
                    <div style={{ fontWeight: "800", padding: "8px 0 0 2rem" }}>
                        Select Country
                    </div>
                    <div className="position-absolute z-2 top--15 right-10">
                        <button
                            className="btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100"
                            style={{
                                boxShadow: "0px 0px  15px rgba(0,0,0, 0.6)",
                            }}
                            onClick={() => closeFilter("residence")}
                        ></button>
                    </div>
                    <div className="overflow-hidden mb-3 pt-2">
                        <div className="picker w-100 d-inline-grid position-relative bg-white">
                            <input
                                value={residenceFilter}
                                className="w-80 mx-auto mt-3"
                                placeholder="Search"
                                onInput={(event) =>
                                    handleResidenceFilter(event)
                                }
                            />
                            <div
                                className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50"
                                style={{
                                    transform: "translateY(0)",
                                }}
                            ></div>
                            <ul
                                className="max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5"
                                ref={residenceUL}
                            >
                                {countriesFiltered.length > 0 ? (
                                    countriesFiltered.map((country, index) => (
                                        <li
                                            className="option-list list-group-item border-0 text-center"
                                            name={country.country_name}
                                            value={country.country_name}
                                            key={index}
                                            code={country.country_code}
                                        >
                                            {country.country_name}
                                        </li>
                                    ))
                                ) : (
                                    <li className="mb-0 mx-auto">No result</li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            className="btn bg-wafaa-purple w-90 text-white"
                            onClick={() => {
                                handleResidenceSelect();
                            }}
                        >
                            {" "}
                            DONE{" "}
                        </button>
                    </div>
                </div>
            </div>

            <div
                className={
                    "max-width-700 bg-white w-100 bottom-0 position-fixed z-2 transition-0-2-ease " +
                    nationalityDisplay
                }
                style={{ boxShadow: "0px 0px 10px 1000px rgba(0,0,0, 0.6)" }}
            >
                <div className="h-100 w-100 position-relative bg-transparent">
                    <div style={{ fontWeight: "800", padding: "8px 0 0 2rem" }}>
                        Select Nationality
                    </div>
                    <div className="position-absolute z-2 top--15 right-10">
                        <button
                            className="btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100"
                            style={{
                                boxShadow: "0px 0px  15px rgba(0,0,0, 0.6)",
                            }}
                            onClick={() => closeFilter("nationality")}
                        ></button>
                    </div>
                    <div className="overflow-hidden mb-3 pt-2">
                        <div className="picker w-100 d-inline-grid position-relative bg-white">
                            <input
                                value={nationalityFilter}
                                className="w-80 mx-auto mt-3"
                                placeholder="Search"
                                onChange={(event) =>
                                    handleNationalityChange(event)
                                }
                            />
                            <div
                                className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50"
                                style={{
                                    transform: "translateY(0)",
                                }}
                            ></div>
                            <ul
                                className="max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5"
                                ref={nationalityUL}
                            >
                                {nationalityFiltered.length > 0 ? (
                                    nationalityFiltered.map(
                                        (country, index) => (
                                            <li
                                                className="option-list list-group-item border-0 text-center"
                                                name={country.country_name}
                                                value={country.country_name}
                                                key={index}
                                            >
                                                {country.country_name}
                                            </li>
                                        )
                                    )
                                ) : (
                                    <li className="mb-0 mx-auto">No result</li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            className="btn bg-wafaa-purple w-90 text-white"
                            onClick={() => {
                                handleNationalitySelect();
                            }}
                        >
                            {" "}
                            DONE{" "}
                        </button>
                    </div>
                </div>
            </div>

            <div
                className={
                    "max-width-700 bg-white w-100 bg-primary bottom-0 position-fixed z-2 transition-0-2-ease " +
                    genderDisplay
                }
                style={{ boxShadow: "0px 0px 10px 1000px rgba(0,0,0, 0.6)" }}
            >
                <div className="h-100 w-100 position-relative bg-transparent">
                    <div style={{ fontWeight: "800", padding: "8px 0 0 2rem" }}>
                        Select Gender
                    </div>
                    <div className="position-absolute z-2 top--15 right-10">
                        <button
                            style={{
                                boxShadow: "0px 0px  15px rgba(0,0,0, 0.6)",
                            }}
                            className="btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100"
                            onClick={() => closeFilter("gender")}
                        ></button>
                    </div>
                    <div className="overflow-hidden mb-3 pt-2">
                        <div className="picker w-100 d-inline-grid position-relative bg-white">
                            <div
                                className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50"
                                style={{
                                    transform: "translateY(0)",
                                }}
                            ></div>
                            <ul
                                className="max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5 mt-4"
                                ref={genderUL}
                            >
                                <li
                                    className="option-list list-group-item border-0 text-center"
                                    value="MALE"
                                >
                                    MALE
                                </li>
                                <li
                                    className="option-list list-group-item border-0 text-center"
                                    value="FEMALE"
                                >
                                    FEMALE
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            className="btn bg-wafaa-purple w-90 text-white"
                            onClick={() => {
                                handleGenderSelect();
                            }}
                        >
                            {" "}
                            DONE{" "}
                        </button>
                    </div>
                </div>
            </div>

            <div
                className={
                    "max-width-700 bg-white w-100 bottom-0 position-fixed z-2 transition-0-2-ease " +
                    cityDisplay 
                }
                style={{ boxShadow: "0px 0px 10px 1000px rgba(0,0,0, 0.6)" }}
            >
                <div className="h-100 w-100 position-relative bg-transparent">
                    <div style={{ fontWeight: "800", padding: "8px 0 0 2rem" }}>
                        Select City
                    </div>
                    <div className="position-absolute z-2 top--15 right-10">
                        <button
                            className="btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100"
                            style={{
                                boxShadow: "0px 0px  15px rgba(0,0,0, 0.6)",
                            }}
                            onClick={() => closeFilter("city")}
                        ></button>
                    </div>
                    <div className="overflow-hidden mb-3 pt-2">
                        <div className="picker w-100 d-inline-grid position-relative bg-white">
                            <input
                                value={cityFilterValue}
                                className="w-80 mx-auto mt-2  mb-2"
                                placeholder="Search"
                                onInput={(event) => handleCityFilter(event)}
                            />
                            <div
                                className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50"
                                style={{
                                    transform: "translateY(0)",
                                }}
                            ></div>
                            <ul
                                className="max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5"
                                ref={cityUL}
                            >
                                {filteredCities.length > 0 ? (
                                    filteredCities.map((city, index) => (
                                        <li
                                            className="option-list list-group-item border-0 text-center"
                                            name={city.city_name}
                                            value={city.city_name}
                                            key={index}
                                            code={city.city_code}
                                        >
                                            {city.city_name}
                                        </li>
                                    ))
                                ) : (
                                    <li className="mb-0 text-center">
                                        Select country first or no city present
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            className="btn bg-wafaa-purple w-90 text-white"
                            onClick={() => {
                                handleCitySelect();
                            }}
                        >
                            {" "}
                            DONE{" "}
                        </button>
                    </div>
                </div>
            </div>

            <div
                className={
                    "max-width-700 bg-white w-100 bottom-0 position-fixed z-2 transition-0-2-ease " +
                    areaDisplay
                }
                style={{ boxShadow: "0px 0px 10px 1000px rgba(0,0,0, 0.6)" }}
            >
                <div className="h-100 w-100 position-relative bg-transparent">
                    <div style={{ fontWeight: "800", padding: "8px 0 0 2rem" }}>
                        Select Area
                    </div>
                    <div className="position-absolute z-2 top--15 right-10">
                        <button
                            className="btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100"
                            style={{
                                boxShadow: "0px 0px  15px rgba(0,0,0, 0.6)",
                            }}
                            onClick={() => closeFilter("area")}
                        ></button>
                    </div>
                    <div className="overflow-hidden mb-3 pt-2">
                        <div className="picker w-100 d-inline-grid position-relative bg-white">
                            <input
                                value={areaFilterValue}
                                className="w-80 mx-auto mt-2  mb-2"
                                placeholder="Search"
                                onInput={(event) => handleAreaFilter(event)}
                            />
                            <div
                                className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50"
                                style={{
                                    transform: "translateY(0)",
                                }}
                            ></div>
                            <ul
                                className="max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5"
                                ref={areaUL}
                            >
                                {filteredAreas.length > 0 ? (
                                    filteredAreas.map((area, index) => (
                                        <li
                                            className="option-list list-group-item border-0 text-center"
                                            name={area.area_name}
                                            value={area.area_name}
                                            key={index}
                                            code={area.area_code}
                                        >
                                            {area.area_name}
                                        </li>
                                    ))
                                ) : (
                                    <li className="mb-0 text-center">
                                        Select city first or no area present
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            className="btn bg-wafaa-purple w-90 text-white"
                            onClick={() => {
                                handleAreaSelect();
                            }}
                        >
                            {" "}
                            DONE{" "}
                        </button>
                    </div>
                </div>
            </div>
        </AnimatedPage>
    );
}

export default SignUp;
