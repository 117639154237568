import iosShare from '../../images/iosShare.png'

export default function IosMessageDialog() {
    return (
        <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5 font-family-poppins" id="exampleModalLabel">
                            Install App
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <p className="mb-3">
                            Follow these steps to add the app to your home
                            screen:
                        </p>
                        <ol className="ps-3">
                            <li>
                                Open this page in <strong>Safari</strong>.
                            </li>
                            <li>
                                Tap the{" "}
                                <img style={{marginTop: "-5px"}} src={iosShare} width={"20px"} height={"20px"} alt="share"/>
                                {" "}button at the bottom of the screen.
                            </li>
                            <li>
                                Select <strong>"Add to Home Screen"</strong>{" "}
                                from the options.
                            </li>
                            <li>
                                Tap <strong>"Add"</strong> to confirm.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
}
