import { useEffect, useRef, useState } from "react";
import getImgNameFromURL from "../../../Utils/getImgNameFromURL";
import countries from "../../Auth/countries.json";
import search from "../../../images/search.svg"

function NationalityModal({
    setCustomerData,
    customerData,
    customerNationality,
    modalID,
    baseURL,
    setInputHeight,
}) {
    const closeButton = useRef();
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredNationalities, setFilteredNationalities] =
        useState(countries);

    useEffect(() => {
        filterNationalities(searchQuery);
    }, [searchQuery]);

    function filterNationalities(query) {
        if (!query) {
            setFilteredNationalities(countries);
        } else {
            const filtered = countries.filter((country) =>
                country.nationality.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredNationalities(filtered);
        }
    }

    async function update(event) {
        event.preventDefault();

        const formData = new FormData(event.target);

        const payLoad = {
            DIV_DES: "",
            DIV_ID: "1",
            FUNCTION: "LoyUpdateCustomer",
            SEND_KEY: "123456",
            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                TYPE_NAME: "CUSTOMER_UPDATE",
                CUSTOMER_DETAILS: {
                    CUST_CODE: customerData.CUST_CODE,
                    FIRSTNAME: customerData.CUST_NAME.split(" ")[0],
                    LASTNAME: customerData.CUST_NAME.split(" ")[1],
                    MOBILE: customerData.MOBILE,
                    EMAIL: customerData.EMAIL,
                    ADDR1: customerData.ADDR1,
                    ADDR2: customerData.ADDR2,
                    ADDR3: customerData.ADDR3,
                    BIRTHDATE: customerData.BIRTH_DATE,
                    COMPNAME: customerData.COMP_NAME,
                    NATIONALITY: formData.get("nationality"),
                    GENDER: customerData.GENDER,
                    country_code: customerData.country_code,
                    city_code: customerData.city_code,
                    area_code: customerData.area_code,
                    CITY: customerData.CITY,
                    AREA: customerData.AREA,
                    COUNTRY: customerData.COUNTRY,
                    profile_img: getImgNameFromURL(customerData.PROFILE_IMAGE),
                },
            },
        };

        const request = await fetch(`${baseURL}/LoyUpdateCustomer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payLoad),
        });

        const response = await request.json();

        if (response.STATUS) {
            customerData.NATIONALITY = formData.get("nationality");
            setCustomerData({ ...customerData });
            closeButton.current?.click();
        } else {
            closeButton.current?.click();
            alert("Failed to update");
        }
    }

    return (
        <>
            <div
                className="modal fade max-width-700 start-50"
                style={{
                    transform: "translateX(-50%)",
                }}
                id={modalID}
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-fullscreen max-width-700">
                    <div className="modal-content">
                        <div className="modal-header shadow bg-wafaa-white-2">
                            <h5 className="modal-title text-wafaa-black-1">
                                Change Nationality
                            </h5>
                            <button
                                type="button"
                                className="text-wafaa-black-1 bg-transparent border-0"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{
                                    transform: "scale(2)",
                                }}
                                ref={closeButton}
                            >
                                &times;
                            </button>
                        </div>
                        <form
                            onSubmit={update}
                            className="h-100 d-flex flex-column"
                        >
                            <div className="w-100 h-80 overflow-y-scroll pb-4 bg-wafaa-white-2">
                                <div
                                    className={`w-100 d-flex justify-content-center position-relative`}
                                    style={{
                                        transition: "all 0.2s ease-in-out",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "90%",
                                        }}
                                    >
                                        <input
                                            className={`w-100 transition-0-2-ease-in position-relative z-1`}
                                            type="text"
                                            // placeholder=""
                                            value={searchQuery}
                                            onChange={(e) =>
                                                setSearchQuery(e.target.value)
                                            }
                                            style={{
                                                paddingLeft: "38px", // Add padding to the left for the icon
                                                paddingBlock: "10px",
                                                margin: "10px 0",
                                                background: "transparent",
                                                // border: `1.5px solid #ccc`,
                                                borderRadius: "8px",
                                                border: `${
                                                    searchQuery ? "2px" : "1px"
                                                  } solid ${searchQuery ? "#182596" : "#ccc"}`,
                                                transition:
                                                    "border-color 0.2s ease-in-out", // Transition for border color
                                                width: "100%", // Full width of the parent
                                            }}
                                        />
                                        <span
                                            className="position-absolute"
                                            style={{
                                                left: "10px",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                pointerEvents: "none", // Prevent pointer events on the icon
                                                color: "#999", // Color of the icon
                                                fontSize: "14px", // Adjust icon size if needed
                                            }}
                                        >
                                            {/* <i className="fas fa-search" style={{ fontSize: "16px"}}></i>{" "} */}
                                            <img src={search} alt='search' width={"25px"} height={"25px"} style={{opacity: "0.75"}}/>{" "}
                                            {!searchQuery &&"Search Nationality"}
                                            {/* Font Awesome search icon */}
                                        </span>
                                    </div>
                                </div>

                                {filteredNationalities.length > 0 ? (
                                    filteredNationalities.map((country) => (
                                        <label
                                            key={country.en_short_name}
                                            className="form-check-label p-2 d-flex justify-content-between border-bottom text-wafaa-black-1"
                                        >
                                            <div>{country.nationality}</div>
                                            {country.nationality ===
                                            customerNationality ? (
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="nationality"
                                                    defaultValue={
                                                        country.nationality
                                                    }
                                                    defaultChecked
                                                />
                                            ) : (
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="nationality"
                                                    defaultValue={
                                                        country.nationality
                                                    }
                                                />
                                            )}
                                        </label>
                                    ))
                                ) : (
                                    <div className="text-center text-wafaa-black-1 mb-0">
                                        No nationalities found
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer bg-wafaa-white-2">
                                <button className="w-100 btn btn-lg py-2-5 btn-primary bg-wafaa-purple-2 border-wafaa-purple shadow-wafaa-btn font-size-12">
                                    DONE
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NationalityModal;
