import { Link } from 'react-router-dom';

function SingleItem({ section }) {

    return (
        <Link to='/about/single' state={section} className='text-decoration-none'>
            <div className='shadow bg-wafaa-white-2 mx-3 my-3 rounded-2 py-1'>
                <p className='w-100 mx-auto border-1 mb-0 border-top-0 border-end-0 border-start-0 mb-0 p-2 font-size-14 d-flex justify-content-between font-weight-500'>
                    <span className='font-weight-bolder text-wafaa-black-1'>{ section.Heading }</span>
                    <span className='text-wafaa-grey'>
                        &gt;
                    </span>
                </p>
            </div>
        </Link>
    )
}

export default SingleItem;
