import React from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import digital_receipt from '../../images/digital_receipt.jpg';
import close_icon from '../../images/icons/material-symbols_close.svg';
import { Link, useNavigate } from 'react-router-dom';

function DigitalReceipt() {
    let navigate = useNavigate();
    return (
        <AnimatedPage>
            <div className='w-100 overflow-y-scroll text-center'>
                <div className='bg-white height-70'>
                    <Link onClick={() => navigate(-1)} className='position-absolute top-20 right-10 z-1 shadow rounded-circle'>
                        <img src={close_icon} alt='Close icon'/>
                    </Link>
                </div>

                <img src={digital_receipt} className='w-95 mx-auto mt-3' alt='Digital receipt'/>


            </div>
        </AnimatedPage>
    );
}

export default DigitalReceipt;
