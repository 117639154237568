import React, { useRef } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import bg_circles from '../../images/bg-circle.svg';
import back_arrow from '../../images/icons/material-symbols_arrow-back-ios-new.svg';
import { useNavigate } from "react-router-dom";
import Alert from './Alert/Alert';
import Cookies from 'js-cookie';
import useAlert from "../../hooks/Alert";


function ForgotPassword({ baseURL, clientID }) {

    const navigate = useNavigate();

    const {showAlert, setShowAlert, alertStyle, setAlertStyle, alertMessage, setAlertMessage, hideAlert} = useAlert();

    const emailInput = useRef();

    const sendPasswordResetEmail = async () => {
        if (emailInput.current.checkValidity()) {
            const payLoad = {
                "DIV_ID": "1",
                "FUNCTION": "SendOTP",
                "SEND_KEY": "123456",
                "DATA": {
                    "EMAIL_ID": emailInput.current.value,
                    "CLIENT_ID": clientID
                }
            }

            const request = await fetch(`${baseURL}/SendOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payLoad)
            })

            const response = await request.json();

            if (response.SUCCESS === "false") {
                hideAlert();
                setAlertStyle({
                    background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                })
                setAlertMessage({
                    success: false,
                    message: response.MESSAGE,
                    mode: 'Forgot password'
                });
                setShowAlert('d-block');
            } else {
                // send email
                const sendEmailPayload = {
                    "TemplateFile": "email/otpmail.json",
                    "CLIENT_ID": clientID,
                    "mailTo": [
                        {
                            "MailId": response.DATA.EMAIL_ID,
                            "MailName": "05533"
                        }
                    ],
                    "paramData": [
                        {
                            "OTP": response.DATA.OTP,
                            "MailName": ""
                        }
                    ]

                }

                const sendEmailRequest = await fetch(`${baseURL}/LoyaltyEssOtpEmailSender`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sendEmailPayload)
                })

                if (sendEmailRequest.ok) {
                    Cookies.set('wafaa-user-data-reset-email', JSON.stringify(response.DATA.EMAIL_ID));
                    navigate('/forgot-password-otp')
                } else {
                    hideAlert();
                    setAlertStyle({
                        background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                    })
                    setAlertMessage({
                        success: false,
                        message: "Failed to send email. Please retry",
                        mode: 'Forgot password'
                    });
                    setShowAlert('d-block');
                }
            }

        } else {
            hideAlert();
            setAlertStyle({
                background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
            });
            setAlertMessage({
                success: false,
                message: 'Email input is not valid',
                mode: 'Forgot password'
            });
            setShowAlert('d-block');
        }
    }

    return (
        <AnimatedPage>
            <div className='w-100 bg-wafaa-purple align-items-center text-white font-size-16 h-50' style={{
                backgroundImage: `url(${bg_circles})`
            }}>
                <Alert showAlert={showAlert} alertStyle={alertStyle} alertMessage={alertMessage} hideAlert={hideAlert} />
                <span className='d-block me-2' onClick={() => navigate(-1)}>
                    <img src={back_arrow} alt='Back button iOS' />
                </span>
                <div className='d-flex flex-column text-white px-4 pt-4 pb-5'>
                    <div>
                        <p className='font-size-25 font-weight-bolder'>Forgot<br />Password?</p>
                        <p className='mb-0 text-wafaa-white font-size-12'>
                            Don't worry ! It happens. Please enter the email we will send the OTP
                        </p>
                    </div>
                    <input className='bg-wafaa-grey border-0 rounded-2 mt-4 ps-2 py-3 shadow-wafaa-btn-inset form-control' placeholder='Enter the Email' ref={emailInput} type='email' required />
                </div>
            </div>

            <div className='position-absolute d-flex flex-column justify-content-end p-4 w-100 bottom-0'>
                <div className='text-center'>
                    <button type='button' onClick={() => sendPasswordResetEmail()} className='w-100 btn btn-lg py-2-5 btn-primary bg-wafaa-purple border-wafaa-purple shadow-wafaa-btn font-size-12'>
                        Continue
                    </button>
                </div>
            </div>
        </AnimatedPage>
    );
}

export default ForgotPassword;
