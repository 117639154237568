import React from 'react';
import { Link } from 'react-router-dom';

function AuthOptions({ setAuthScreen, authOptionPage }) {

    return (
        <div className='h-85 pt-4 d-flex justify-content-center align-items-center'>
            {
                authOptionPage === "login" ? (
                    <div className='d-flex flex-column w-95 transition-0-5-ease-in pt-2'>
                        <p className='font-size-17 font-weight-700 text-wafaa-dark-blue mb-2'>Login with:</p>
                        <Link className='btn btn-lg py-2-5 btn-primary bg-wafaa-purple border-wafaa-purple shadow-wafaa-btn font-size-12' to='/login'>
                            Mobile number/ Email
                        </Link>
                        <button className='btn btn-lg py-2-5 mt-2 font-size-12 text-wafaa-dark-blue' onClick={() => setAuthScreen('login-signup')}>
                            Cancel
                        </button>
                    </div>
                ) : (
                    <div className='d-flex flex-column w-95 transition-2-ease-in pt-2'>
                        <p className='font-size-17 font-weight-700 text-wafaa-dark-blue mb-2'>Start your journey:</p>
                        <Link className='btn btn-lg py-2-5 btn-primary bg-wafaa-purple border-wafaa-purple shadow-wafaa-btn font-size-12' to='/join'>
                            Join with mobile number
                        </Link>
                        <button className='btn btn-lg py-2-5 mt-2 font-size-12 text-wafaa-dark-blue' onClick={() => setAuthScreen('login-signup')}>
                            Cancel
                        </button>
                    </div>
                )
            }
        </div>
    );
}

export default AuthOptions;
