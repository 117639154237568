import { useRef } from "react";
import getImgNameFromURL from "../../../Utils/getImgNameFromURL"

function GenderModal({
    setCustomerData,
    customerData,
    customerGender,
    modalID,
    baseURL,
}) {
    const closeButton = useRef();

    async function update(event) {
        event.preventDefault();

        const formData = new FormData(event.target);

        const payLoad = {
            DIV_DES: "",
            DIV_ID: "1",
            FUNCTION: "LoyUpdateCustomer",
            SEND_KEY: "123456",
            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                TYPE_NAME: "CUSTOMER_UPDATE",
                CUSTOMER_DETAILS: {
                    CUST_CODE: customerData.CUST_CODE,
                    FIRSTNAME: customerData.CUST_NAME.split(" ")[0],
                    LASTNAME: customerData.CUST_NAME.split(" ")[1],
                    MOBILE: customerData.MOBILE,
                    EMAIL: customerData.EMAIL,
                    ADDR1: customerData.ADDR1,
                    ADDR2: customerData.ADDR2,
                    ADDR3: customerData.ADDR3,
                    BIRTHDATE: customerData.BIRTH_DATE,
                    COMPNAME: customerData.COMP_NAME,
                    NATIONALITY: customerData.NATIONALITY,
                    GENDER: formData.get("gender"),
                    country_code: customerData.country_code,
                    city_code: customerData.city_code,
                    area_code: customerData.area_code,
                    CITY: customerData.CITY,
                    AREA: customerData.AREA,
                    COUNTRY: customerData.COUNTRY,
                    profile_img: getImgNameFromURL(customerData.PROFILE_IMAGE)
                },
            },
        };
        const request = await fetch(`${baseURL}/LoyUpdateCustomer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payLoad),
        });

        const response = await request.json();

        if (response.STATUS) {
            customerData.GENDER = formData.get("gender");
            setCustomerData({ ...customerData });
            closeButton.current?.click();
        } else {
            closeButton.current?.click();
            alert("Failed to update");
        }
    }

    return (
        <>
            <div
                className="modal fade max-width-700 start-50"
                style={{
                    transform: "translateX(-50%)",
                }}
                id={modalID}
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-fullscreen max-width-700">
                    <div className="modal-content">
                        <div className="modal-header shadow bg-wafaa-white-2">
                            <h5 className="modal-title text-wafaa-black-1">
                                Change Gender
                            </h5>
                            <button
                                type="button"
                                className="text-wafaa-black-1 bg-transparent border-0"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{
                                    transform: "scale(1.8)",
                                }}
                                ref={closeButton}
                            >
                                &times;
                            </button>
                        </div>
                        <form
                            onSubmit={(event) => {
                                update(event);
                            }}
                            className="h-100 d-flex flex-column"
                        >
                            <div className="modal-body px-0 bg-wafaa-white-3">
                                <label className="form-check-label p-2 d-flex justify-content-between border-bottom text-wafaa-black-1">
                                    <div>Male</div>
                                    {customerGender === "Male" ? (
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            value="Male"
                                            defaultChecked
                                        />
                                    ) : (
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            value="Male"
                                        />
                                    )}
                                </label>
                                <label className="form-check-label p-2 d-flex justify-content-between border-bottom text-wafaa-black-1">
                                    <div>Female</div>
                                    {customerGender === "Female" ? (
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            value="Female"
                                            defaultChecked
                                        />
                                    ) : (
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            value="Female"
                                        />
                                    )}
                                </label>
                            </div>
                            <div className="modal-footer bg-wafaa-white-2">
                                <button className="w-100 btn btn-lg py-2-5 btn-primary bg-wafaa-purple-2 border-wafaa-purple shadow-wafaa-btn font-size-12">
                                    DONE
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GenderModal;
