import { motion } from "framer-motion";
import React, { useState } from "react";
import brokenImage from "../../images/brokenImage.jpg";

const ImageCarousel = ({ images, imageURL }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImgLoading, setIsImgLoading] = useState(true);
    const [isModalImgLoading, setIsModalImgLoading] = useState(true);

    // Guard clause to prevent errors when images array is empty or undefined
    if (!images) {
        return <div>No images available</div>;
    }

    const scrollCarousel = (direction) => {
        setIsImgLoading(true); // Set loading to true when changing images
        if (direction === "left") {
            setCurrentIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : images.length - 1
            );
        } else if (direction === "right") {
            setCurrentIndex((prevIndex) =>
                prevIndex < images.length - 1 ? prevIndex + 1 : 0
            );
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    const styles = {
        carouselContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "100%",
            maxWidth: "700px",
            margin: "auto",
        },
        arrowButtonLeft: {
            position: "absolute",
            left: "10px",
            zIndex: 1,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            cursor: "pointer",
            fontSize: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        arrowButtonRight: {
            position: "absolute",
            right: "10px",
            zIndex: 1,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            cursor: "pointer",
            fontSize: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        imageContainer: {
            width: "100%",
            height: "420px",
            position: "relative",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
        image: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "8px",
            cursor: "pointer",
            filter: isImgLoading ? "blur(10px)" : "none",
            transition: "filter 0.3s ease-out",
        },
        messageContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            color: "#555",
        },
        messageText: {
            fontSize: "18px",
            fontWeight: "bold",
        },
        dotsContainer: {
            position: "absolute",
            bottom: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        },
        dot: {
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            margin: "0 6px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
        },
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(8px)",
            zIndex: 1040, // Ensure it appears behind the modal but above other content
        },
        modal: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1050,
            maxHeight: "90dvh",
            width: "95%", // Increase width to 90%
            // maxWidth: "800px", // Increase maxWidth to 800px or more
            borderRadius: "8px",
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
            overflow: "visible",
        },
        modalContent: {
            position: "relative",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        modalImage: {
            height: "100%",
            width: "100%",
            borderRadius: "8px",
            objectFit: "cover",
            filter: isModalImgLoading ? "blur(8px)" : "none",
                                transition: "filter 0.3s ease-out"
        },
        closeButton: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: "999",
            top: "-18px",
            right: "-9px",
            backgroundColor: "rgba(255, 255, 255, 1)",
            border: "none",
            borderRadius: "50%",
            fontSize: "42px",
            height: "35px",
            width: "35px",
            cursor: "pointer",
            color: 'black',
            // backdropFilter: "blur(5px)", // adds the glass effect with a blur
            // WebkitBackdropFilter: "blur(5px)", // for Safari support
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)" // optional: adds a slight shadow for depth
        },
        
    };

    return (
        <div style={styles.carouselContainer}>
            {/* Left Arrow */}
            <button style={styles.arrowButtonLeft} onClick={() => scrollCarousel("left")}>
                {"<"}
            </button>

            {/* Carousel Image */}
            <div style={styles.imageContainer}>
                {images.length > 0 ? (
                    <motion.img
                        src={imageURL + images[currentIndex].PROMO_IMAGE}
                        alt={`Carousel ${currentIndex + 1}`}
                        style={styles.image}
                        key={currentIndex}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        onClick={openModal} // Open modal on image click
                        onError={(e) => {
                            e.target.src = brokenImage;
                            setIsImgLoading(false);
                        }}
                        onLoad={() => setIsImgLoading(false)}
                        loading="lazy" // Enables native lazy loading
                    />
                ) : (
                    <div style={styles.messageContainer}>
                        <p style={styles.messageText}>No images available</p>
                    </div>
                )}

                {/* Loading Placeholder */}
                {/* {isImgLoading && (
                    <div style={styles.messageContainer}>
                        <p style={styles.messageText}>Loading...</p>
                    </div>
                )} */}

                {/* Dots navigation */}
                {images.length > 0 && (
                    <div style={styles.dotsContainer}>
                        {images.map((_, index) => (
                            <div
                                key={index}
                                onClick={() => setCurrentIndex(index)}
                                style={{
                                    ...styles.dot,
                                    backgroundColor: currentIndex === index ? "#007bff" : "#ccc",
                                }}
                            />
                        ))}
                    </div>
                )}
            </div>

            {/* Right Arrow */}
            <button style={styles.arrowButtonRight} onClick={() => scrollCarousel("right")}>
                {">"}
            </button>

            {/* Background Overlay */}
            {isModalOpen && (
                <div style={styles.overlay} onClick={closeModal}></div>
            )}

            {/* Modal */}
            {isModalOpen && (
                <div style={styles.modal}>
                    <div style={styles.modalContent}>
                        <button style={styles.closeButton} onClick={closeModal}>
                            ×
                        </button>
                        <img
                            src={imageURL + images[currentIndex].PROMO_IMAGE}
                            alt={`Preview ${currentIndex + 1}`}
                            style={styles.modalImage}
                            onLoad={() => setIsModalImgLoading(false)}
                            onError={(e) => {
                                e.target.src = brokenImage;
                                setIsModalImgLoading(false);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageCarousel;
